<template>
    <div
        class="
            flex
            flex-col
            justify-center
            py-6
            cursor-pointer
            border-b
            border-b-primary-400
            first:mt-0
            last:pb-0
            last:border-b-0
        "
    >
        <div
            class="flex pr-2"
            :class="[accentClasses.title]"
            @click="setActiveItem(name)"
        >
            <p class="font-semibold mb-1">
                {{ title }}
            </p>
            <div class="ml-auto">
                <base-icon
                    size="xl"
                    :icon="icon"
                ></base-icon>
            </div>
        </div>
        <expand-transition>
            <base-text :accent="accentClasses.text" v-if="isOpen">
                <slot></slot>
            </base-text>
        </expand-transition>
    </div>
</template>

<script>
    import BaseText from '@/components/typography/BaseText.vue';
    import BaseIcon from '@/components/icon/BaseIcon.vue';
    import ExpandTransition from '@/components/transitions/ExpandTransition.vue';

    export default {
        name: 'accordion-item',
        components: {
            BaseText,
            BaseIcon,
            ExpandTransition,
        },
        inject: [
            'currentItem',
            'setActiveItem',
        ],
        props: {
            title: {
                type: String,
                default: '',
            },
            name: {
                type: String,
                default: '',
            },
            accent: {
                type: String,
                default: 'light',
            },
        },
        computed: {
            isOpen() {
                return this.currentItem === this.name;
            },
            icon() {
                if (this.isOpen) {
                    return ['far', 'circle-minus'];
                }

                return ['far', 'circle-plus'];
            },
            accentClasses() {
                const accentMap = {
                    light: {
                        title: ['text-white'],
                        text: 'primary-light',
                    },
                    dark: {
                        title: ['text-black'],
                        text: 'light',
                    },
                };

                return accentMap[this.accent];
            },
        },
    };
</script>

<template>
    <input
        type="text"
        :class="field.inputClasses"
        :value="field.modelValue"
        @input="field.updateModelValue($event.target.value)"
    >
</template>

<script>
    export default {
        name: 'input-text',
        props: {
            field: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
    };
</script>

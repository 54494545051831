<template>
    <textarea
        rows="5"
        :class="field.inputClasses"
        :value="field.modelValue"
        @input="field.updateModelValue($event.target.value)"
    ></textarea>
</template>

<script>
    export default {
        name: 'input-textarea',
        props: {
            field: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
    };
</script>

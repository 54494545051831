<template>
    <div class="p-2 block xl:p-4" :class="[columnClasses]">
        <slot></slot>
    </div>
</template>

<script>
    function breakpointValidator(value) {
        return [
            '',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            'auto',
            'narrow',
        ].includes(value);
    }

    export default {
        name: 'flex-column',
        props: {
            sm: {
                type: String,
                default: 'auto',
                validator: breakpointValidator,
            },
            md: {
                type: String,
                default: '',
                validator: breakpointValidator,
            },
            lg: {
                type: String,
                default: '',
                validator: breakpointValidator,
            },
            xl: {
                type: String,
                default: '',
                validator: breakpointValidator,
            },
            '2xl': {
                type: String,
                default: '',
                validator: breakpointValidator,
            },
        },
        computed: {
            columnClasses() {
                /* eslint-disable max-len, object-curly-newline */
                const classes = {
                    1: { sm: 'basis-1/12 max-w-1/12', md: 'md:basis-1/12 md:max-w-1/12', lg: 'lg:max-w-1/12', xl: 'xl:max-w-1/12', '2xl': '2xl:max-w-1/12' },
                    2: { sm: 'basis-2/12 max-w-2/12', md: 'md:basis-2/12 md:max-w-2/12', lg: 'lg:max-w-2/12', xl: 'xl:max-w-2/12', '2xl': '2xl:max-w-2/12' },
                    3: { sm: 'basis-3/12 max-w-3/12', md: 'md:basis-3/12 md:max-w-3/12', lg: 'lg:max-w-3/12', xl: 'xl:max-w-3/12', '2xl': '2xl:max-w-3/12' },
                    4: { sm: 'basis-4/12 max-w-4/12', md: 'md:basis-4/12 md:max-w-4/12', lg: 'lg:max-w-4/12', xl: 'xl:max-w-4/12', '2xl': '2xl:max-w-4/12' },
                    5: { sm: 'basis-5/12 max-w-5/12', md: 'md:basis-5/12 md:max-w-5/12', lg: 'lg:max-w-5/12', xl: 'xl:max-w-5/12', '2xl': '2xl:max-w-5/12' },
                    6: { sm: 'basis-6/12 max-w-6/12', md: 'md:basis-6/12 md:max-w-6/12', lg: 'lg:max-w-6/12', xl: 'xl:max-w-6/12', '2xl': '2xl:max-w-6/12' },
                    7: { sm: 'basis-7/12 max-w-7/12', md: 'md:basis-7/12 md:max-w-7/12', lg: 'lg:max-w-7/12', xl: 'xl:max-w-7/12', '2xl': '2xl:max-w-7/12' },
                    8: { sm: 'basis-8/12 max-w-8/12', md: 'md:basis-8/12 md:max-w-8/12', lg: 'lg:max-w-8/12', xl: 'xl:max-w-8/12', '2xl': '2xl:max-w-8/12' },
                    9: { sm: 'basis-9/12 max-w-9/12', md: 'md:basis-9/12 md:max-w-9/12', lg: 'lg:max-w-9/12', xl: 'xl:max-w-9/12', '2xl': '2xl:max-w-9/12' },
                    10: { sm: 'basis-10/12 max-w-10/12', md: 'md:basis-10/12 md:max-w-10/12', lg: 'lg:max-w-10/12', xl: 'xl:max-w-10/12', '2xl': '2xl:max-w-10/12' },
                    11: { sm: 'basis-11/12 max-w-11/12', md: 'md:basis-11/12 md:max-w-11/12', lg: 'lg:max-w-11/12', xl: 'xl:max-w-11/12', '2xl': '2xl:max-w-11/12' },
                    12: { sm: 'basis-full max-w-full', md: 'md:basis-full md:max-w-full', lg: 'lg:basis-full lg:max-w-full', xl: 'xl:basis-full xl:max-w-full', '2xl': '2xl:basis-full 2xl:max-w-full' },
                    auto: { sm: 'flex-1', md: 'md:flex-1', lg: 'lg:flex-1', xl: 'xl:flex-1', '2xl': '2xl:flex-1' },
                    narrow: { sm: 'flex-none', md: 'md:flex-none', lg: 'lg:flex-none', xl: 'xl:flex-none', '2xl': '2xl:flex-none' },
                };
                /* eslint-enable max-len, object-curly-newline */

                const responsiveClasses = [
                    classes[this.sm].sm,
                ];

                if (this.md) {
                    responsiveClasses.push(classes[this.md].md);
                }

                if (this.lg) {
                    responsiveClasses.push(classes[this.lg].lg);
                }

                if (this.xl) {
                    responsiveClasses.push(classes[this.xl].xl);
                }

                if (this['2xl']) {
                    responsiveClasses.push(classes[this['2xl']]['2xl']);
                }

                return responsiveClasses;
            },
        },
    };
</script>

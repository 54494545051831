<template>
    <hero-gradient class="mt-auto" :clip-top="true">
        <footer>
            <div class="container mx-auto px-4 pt-16">
                <flex-columns>
                    <flex-column sm="12" lg="6">
                        <img
                            class="w-[140px] lg:w-[200px]"
                            src="@/assets/logo/logo-white.svg"
                        >
                        <base-title
                            class="lg:!text-3xl"
                            size="md"
                            accent="white"
                        >
                            Bestaanszekerheid voor iedereen
                        </base-title>
                    </flex-column>
                    <flex-column sm="12" lg="6">
                        <flex-columns>
                            <flex-column lg="6">
                                <base-title
                                    class="lg:!text-2xl"
                                    accent="white"
                                    size="sm"
                                >
                                    Menu
                                </base-title>
                                <ul class="space-y-3">
                                    <li>
                                        <base-text
                                            class="lg:!text-[18px]"
                                            accent="white"
                                        >
                                            <router-link :to="{ name: 'workers' }">
                                                Voor werkenden
                                            </router-link>
                                        </base-text>
                                    </li>
                                    <li>
                                        <base-text
                                            class="lg:!text-[18px]"
                                            accent="white"
                                        >
                                            <router-link :to="{ name: 'employers' }">
                                                Voor werkgevers
                                            </router-link>
                                        </base-text>
                                    </li>
                                    <li>
                                        <base-text
                                            class="lg:!text-[18px]"
                                            accent="white"
                                        >
                                            <router-link :to="{ name: 'contact' }">
                                                Contact
                                            </router-link>
                                        </base-text>
                                    </li>
                                </ul>
                            </flex-column>
                            <flex-column lg="6">
                                <base-title
                                    class="lg:!text-2xl"
                                    accent="white"
                                    size="sm"
                                >
                                    Neem contact op
                                </base-title>
                                <ul class="space-y-3">
                                    <li>
                                        <base-text accent="white" class="lg:!text-[18px]">
                                            <a
                                                href="tel:0102610266"
                                                class="flex items-center space-x-2"
                                            >
                                                <base-icon :icon="['fas', 'phone']"></base-icon>
                                                <em>010-2610266</em>
                                            </a>
                                        </base-text>
                                    </li>
                                    <li>
                                        <base-text accent="white" class="lg:!text-[18px]">
                                            <a
                                                href="mailto:info@dewerkgever.nl"
                                                class="flex items-center space-x-2"
                                            >
                                                <base-icon :icon="['fas', 'envelope']"></base-icon>
                                                <em>info@dewerkgever.nl</em>
                                            </a>
                                        </base-text>
                                    </li>
                                </ul>
                            </flex-column>
                        </flex-columns>
                    </flex-column>
                </flex-columns>
            </div>
            <div class="mt-16 p-3 text-center bg-black/10 lg:text-left">
                <div class="container mx-auto px-4">
                    <base-text class="!text-[14px]" accent="white">
                        &copy; 2022 DeWerkgever, All right reserved.
                    </base-text>
                </div>
            </div>
        </footer>
    </hero-gradient>
</template>

<script>
    import HeroGradient from '@/components/gradient/HeroGradient.vue';
    import BaseTitle from '@/components/typography/BaseTitle.vue';
    import BaseText from '@/components/typography/BaseText.vue';
    import BaseIcon from '@/components/icon/BaseIcon.vue';
    import FlexColumns from '@/components/columns/FlexColumns.vue';
    import FlexColumn from '@/components/columns/FlexColumn.vue';

    export default {
        name: 'base-footer',
        components: {
            HeroGradient,
            BaseTitle,
            BaseText,
            BaseIcon,
            FlexColumns,
            FlexColumn,
        },
    };
</script>

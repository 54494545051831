<template>
    <transition
        enter-from-class="opacity-0"
        leave-to-class="opacity-0"
        enter-active-class="transition-opacity duration-[120ms]"
        leave-active-class="transition-opacity duration-[120ms]"
    >
        <slot></slot>
    </transition>
</template>

<script>
    export default {
        name: 'fade-transition',
    };
</script>

<template>
    <label class="h-5 cursor-pointer flex w-max max-w-full items-center">
        <input
            type="checkbox"
            class="
                rounded
                w-5
                h-5
                border
                transition-colors
                cursor-pointer
                border-gray-300
                text-secondary-500
                hover:border-gray-400
                active:bg-gray-100
                focus:ring-0
                focus:ring-offset-0
                disabled:cursor-not-allowed
                disabled:checked:bg-none
                disabled:checked:checkmark-gray
                disabled:bg-gray-100
                disabled:text-gray-100
                disabled:border-gray-200
                disabled:hover:border-gray-200
                field-invalid:border-red-500
            "
            v-bind="$attrs"
            :value="value"
            :checked="isChecked"
            @change="checkValue($event)"
        >
        <span class="px-2 text-gray-800 empty:px-0" :class="[typographyClasses]">
            <slot></slot>
        </span>
    </label>
</template>

<script>
    export default {
        name: 'input-checkbox',
        props: {
            field: {
                type: Object,
                default() {
                    return {};
                },
            },
            value: {
                type: [String, Number],
                default: '',
            },
            typographyStyle: {
                type: String,
                default: 'base',
                validator(value) {
                    return [
                        'base',
                        'inherit',
                    ].includes(value);
                },
            },
        },
        computed: {
            hasArrayAsModel() {
                return Array.isArray(this.field.modelValue);
            },
            isChecked() {
                if (this.hasArrayAsModel) {
                    return this.field.modelValue.indexOf(this.value) > -1;
                }

                if (typeof this.field.modelValue === 'boolean') {
                    return this.field.modelValue;
                }

                return this.field.modelValue === this.value;
            },
            typographyClasses() {
                if (this.typographyStyle === 'inherit') {
                    return [];
                }

                return [
                    'font-normal',
                    'font-sans',
                ];
            },
        },
        methods: {
            checkValue(event) {
                if (this.hasArrayAsModel) {
                    const checkedValues = [...this.field.modelValue];
                    const value = typeof this.value === 'number'
                        ? Number(event.target.value)
                        : event.target.value;

                    const index = checkedValues.indexOf(value);

                    if (index > -1) {
                        checkedValues.splice(index, 1);
                    } else {
                        checkedValues.push(value);
                    }

                    return this.field.updateModelValue(checkedValues);
                }

                return this.field.updateModelValue(event.target.checked);
            },
        },
    };
</script>

<template>
    <font-awesome-icon :icon="icon"></font-awesome-icon>
</template>

<script>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    export default {
        name: 'base-icon',
        components: {
            FontAwesomeIcon,
        },
        props: {
            icon: {
                type: [Array, String],
                required: true,
            },
        },
    };
</script>

<template>
    <p class="font-sans font-semibold mb-2" :class="[accentClasses]">
        <slot></slot>
    </p>
</template>

<script>
    export default {
        name: 'base-subtitle',
        props: {
            accent: {
                type: String,
                default: 'primary',
            },
        },
        computed: {
            accentClasses() {
                const accentMap = {
                    primary: ['text-primary-500'],
                    secondary: ['text-secondary-300'],
                };

                return accentMap[this.accent];
            },
        },
    };
</script>

<template>
    <div class="flex flex-col text-left px-4">
        <slot></slot>
    </div>
</template>

<script>
    import { computed } from 'vue';

    export default {
        name: 'base-accordion',
        provide() {
            return {
                currentItem: computed(() => {
                    return this.currentItem;
                }),
                setActiveItem: this.setActiveItem,
            };
        },
        props: {
            activeItem: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                currentItem: this.activeItem,
            };
        },
        watch: {
            activeItem(name) {
                this.currentItem = name;
            },
        },
        methods: {
            setActiveItem(name) {
                if (this.currentItem === name) {
                    this.currentItem = '';

                    return;
                }

                this.currentItem = name;
            },
        },
    };
</script>

<template>
    <div
        class="
            relative
            flex
            flex-col
            overflow-hidden
            rounded-xl
            shadow-xl
            bg-white
        "
    >
        <img
            class="h-[200px] w-full object-cover object-center clip-card-image"
            :src="imageUrl"
        >
        <div
            class="
                flex
                flex-col
                p-5
                h-full
                before:absolute
                before:top-[226px]
                before:-left-[42px]
                before:w-[243px]
                before:h-[243px]
                before:opacity-20
                before:blur-[60px]
                before:card-gradient-blue
                after:absolute
                after:top-[370px]
                after:left-[168px]
                after:w-[278px]
                after:h-[278px]
                after:opacity-20
                after:blur-[129px]
                after:card-gradient-yellow
                lg:p-[30px]
            "
        >
            <base-title size="sm">
                {{ title }}
            </base-title>
            <slot></slot>
            <div class="mt-auto">
                <slot name="action"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import BaseTitle from '@/components/typography/BaseTitle.vue';

    export default {
        name: 'base-card',
        components: {
            BaseTitle,
        },
        props: {
            image: {
                type: String,
                default: '',
            },
            title: {
                type: String,
                default: '',
            },
        },
        computed: {
            imageUrl() {
                return new URL(`/src/${this.image}`, import.meta.url).href;
            },
        },
    };
</script>

<template>
    <div>
        <hero-gradient class="text-center py-24 mb-12 xl:py-32 xl:mb-32" :clip-bottom="true">
            <div class="container mx-auto px-4 lg:px-20 xl:px-72">
                <base-subtitle accent="secondary">
                    Over ons
                </base-subtitle>
                <base-title
                    class="lg:!text-5xl"
                    size="sm"
                    accent="white"
                >
                    Een zeker en duurzaam bestaan.
                </base-title>
                <base-text accent="white">
                    Maak onderdeel uit van een systeem dat streeft naar
                    rechtvaardigheid. Naar verbondenheid, gelijkheid en
                    medeleven. We openen ieders ogen dat rechtvaardig,
                    fatsoenlijk werkgeverschap bestaat.
                </base-text>
            </div>
        </hero-gradient>
        <div class="container mx-auto px-4 mb-12 lg:px-20 xl:mb-28">
            <flex-columns class="lg:flex-row-reverse">
                <flex-column sm="12" xl="6">
                    <img
                        src="@/assets/photos/historic-group-of-people-working.jpeg"
                        class="rounded-xl"
                        alt=""
                    >
                </flex-column>
                <flex-column sm="12" xl="6">
                    <base-title size="md" accent="gray">
                        Waarom we dit doen.
                    </base-title>
                    <base-text accent="light">
                        De nadruk in de huidige arbeidsmarkt ligt vooral op het
                        bedrijf en de taak die vervuld moet worden. Het ligt
                        niet op mensen, of op de zekerheid van bestaan.
                        <strong>Het resultaat?</strong> Mensen die afhankelijk
                        zijn van één of meerdere banen. Een wereld waar meer
                        geld wordt verdiend aan arbeid, dan met arbeid.
                    </base-text>
                    <base-text accent="light">
                        Er zijn genoeg werkenden en werkgevers die dit anders
                        zouden willen zien. Die op een fatsoenlijke wijze met
                        elkaar om willen gaan. Maar door de jungle aan regels en
                        sancties raken werkgevers de regie kwijt om het goede te
                        doen. Soms hebben deze regels zelfs een omgekeerd
                        effect.
                    </base-text>
                    <base-text accent="light">
                        Niet langer. <strong>Wij staan voor een zeker bestaan!</strong>
                    </base-text>
                    <base-button
                        download
                        accent="white"
                        :href="ManifestPdf"
                    >
                        <template #icon>
                            <base-icon :icon="['far', 'download']"></base-icon>
                        </template>
                        Download ons manifesto
                    </base-button>
                </flex-column>
            </flex-columns>
        </div>
        <div class="container mx-auto px-4 mb-12 lg:px-20 xl:mb-28">
            <flex-columns class="lg:flex-row-reverse">
                <flex-column sm="12" xl="6">
                    <base-title size="md" accent="gray">
                        Eerlijk en duurzaam.
                    </base-title>
                    <base-text accent="light">
                        <strong>Waarom een coöperatie?</strong> Samen sta je
                        sterk. Met elkaar hebben we meer draagkracht en kunnen
                        we de risico's minimaliseren.
                    </base-text>
                    <base-text accent="light">
                        We zijn ervan overtuigd dat inkomenszekerheid één van de pijlers van
                        bestaanszekerheid is. Een zeker inkomen dat voldoet aan de inkomensbehoefte van
                        nu, én later. Als coöperatie zorgen we ervoor dat iedereen de juiste skills en
                        gezondheid heeft om kansrijk te zijn op de arbeidsmarkt. Wat er ook gebeurd. Met
                        een zeker bestaan is de werkgever wendbaar voor veranderende omstandigheden en
                        de werkende verzekerd van inkomen nu en later.
                    </base-text>
                    <base-text accent="light">
                        Niet langer. <strong>Wij staan voor een zeker bestaan!</strong>
                    </base-text>
                    <button-list>
                        <base-button
                            download
                            accent="white"
                            :href="Document2021"
                        >
                            <template #icon>
                                <base-icon :icon="['far', 'download']"></base-icon>
                            </template>
                            Troonrede 2021
                        </base-button>
                        <base-button
                            accent="white"
                            :to="{ name: 'troonrede' }"
                        >
                            <template #icon>
                                <base-icon :icon="['far', 'eye']"></base-icon>
                            </template>
                            Troonrede 2022
                        </base-button>
                    </button-list>
                </flex-column>
                <flex-column sm="12" xl="6">
                    <img
                        src="@/assets/photos/group-of-young-businesspeople-standing-outdoors.jpeg"
                        class="rounded-xl"
                        alt=""
                    >
                </flex-column>
            </flex-columns>
        </div>
        <div class="container mx-auto px-4 mb-12 lg:px-20 xl:mb-28">
            <flex-columns class="lg:flex-row-reverse">
                <flex-column sm="12" xl="6">
                    <img
                        src="@/assets/photos/businesswoman-outdoors-in-the-city.jpeg"
                        class="rounded-xl"
                        alt=""
                    >
                </flex-column>
                <flex-column sm="12" xl="6">
                    <base-title size="md" accent="gray">
                        Onze oplossing.
                    </base-title>
                    <base-text accent="light">
                        DeWerkgever zorgt ervoor dat de samenwerking tussen
                        werkenden en werkgevers optimaal verloopt. Bij
                        coöperatie DeWerkgever ben je bij ziekte en werkloosheid
                        verzekerd tot je 68e, met behoud van een goed pensioen.
                    </base-text>
                    <base-text accent="light">
                        <strong>Hoe dan?</strong> Binnen de coöperatie kunnen we
                        iedereen inkomenszekerheid verzekeren vanuit moderne
                        solidariteit. Met elkaar investeren we preventief en
                        proactief in de juiste skills en gezondheid zodat je
                        zorgeloos kunt werken en leven.
                    </base-text>
                    <base-text accent="light">
                        Het mes snijdt aan twee kanten bij coöperatie
                        DeWerkgever. We helpen de werkgever om innovatief en
                        succesvol te zijn. Én we helpen alle leden aan een vast
                        contract met de zekerheid van inkomen. <br>
                        <strong>Een gezonde en duurzame loopbaan lang.</strong>
                    </base-text>
                </flex-column>
            </flex-columns>
        </div>
        <hero-gradient class="text-center py-36" :clip-both="true">
            <div class="container mx-auto px-4 lg:px-20 xl:px-72">
                <base-title accent="white" size="md">
                    Met elkaar en voor elkaar.
                </base-title>
                <base-text accent="lightest">
                    Sociale zekerheid is alleen te dragen wanneer we
                    samenwerken. Zo zijn we nu en in de toekomst verzekerd van
                    inkomen. Zowel tijdens ons werkzame leven, als bij ouderdom
                    of ziekte. Met respect en aandacht voor iedereen werken we
                    doelgericht aan rust en zekerheid in de arbeidsrelatie.
                </base-text>
                <img src="@/assets/illustrations/risico.png" alt="">
            </div>
        </hero-gradient>
        <div class="container mx-auto my-32 px-4 lg:px-20">
            <div class="text-center mb-32 xl:px-72">
                <base-title size="md">
                    Hoe ziet de Coöperatie eruit?
                </base-title>
                <base-text accent="light">
                    <strong>De coöperatie wordt door iedereen gestuurd.</strong>
                    Een door de leden aangesteld bestuur maakt afspraken met
                    externe partijen om inkomenszekerheid te verzekeren. Denk
                    daarbij aan afspraken met loopbaancoaches, opleiders en
                    verzekeraars. De partners van de coöperatie en de onderlinge
                    afspraken wordt door de eigen leden bepaald in stuurgroepen.
                    Dit zijn gekozen leden die de verantwoordelijkheid nemen om
                    alles te regelen op het vlak van inkomenszekerheid,
                    gezondheid, skills en impact.
                </base-text>
                <base-text accent="light">
                    De coöperatie is onderverdeeld in
                    <strong>collectieven.</strong> Een collectief bestaat uit 50
                    tot 250 leden. Dit kan één organisatie zijn, of een
                    samenwerking tussen verschillende bedrijven.
                    <strong>Waarom minimaal 50?</strong> Zo kun je een
                    schenkkring vormen en zorgen dat je elkaar kan ondersteunen
                    in tijden van nood.
                    <strong>Waarom maximaal 250?</strong> Zo blijft het
                    persoonlijk en kun je elkaar echt leren kennen. Op deze
                    manier blijft het collectief open en eerlijk.
                </base-text>
                <img
                    class="hidden lg:block"
                    src="@/assets/illustrations/organogram-desktop.png"
                    alt=""
                >
                <img
                    class="lg:hidden"
                    src="@/assets/illustrations/organogram-mobile.png"
                    alt=""
                >
            </div>
        </div>
        <hero-gradient :clip-both="true">
            <div class="container mx-auto px-4 lg:px-16">
                <flex-columns class="lg:flex-row-reverse">
                    <flex-column sm="12" lg="6">
                        <div class="flex justify-center -mx-1 mt-20 mb-16 lg:py-20">
                            <div class="flex flex-col justify-center -mt-20">
                                <logo-cloud-item image="assets/partners/saltagroup.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/digitale-fitheid.svg"></logo-cloud-item>
                            </div>
                            <div class="flex flex-col justify-center">
                                <logo-cloud-item image="assets/partners/academie-werkend-leren.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/ministerie.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/ig.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/fast-forward-group.svg"></logo-cloud-item>
                            </div>
                            <div class="flex flex-col justify-center">
                                <logo-cloud-item image="assets/partners/verloning.svg"></logo-cloud-item>
                                <logo-cloud-item :is-empty="true"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/factris.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/goodhabitz.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/salarise.svg"></logo-cloud-item>
                            </div>
                            <div class="flex flex-col justify-center mt-4">
                                <logo-cloud-item image="assets/partners/abnamro.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/werkvereniging.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/aig.svg"></logo-cloud-item>
                            </div>
                            <div class="flex flex-col justify-center mt-12">
                                <logo-cloud-item image="assets/partners/goed.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/wage-indicator.svg"></logo-cloud-item>
                            </div>
                        </div>
                    </flex-column>
                    <flex-column sm="12" lg="6">
                        <div class="text-center mb-36 lg:mb-0 lg:py-36 lg:text-left">
                            <base-title accent="white" size="md">
                                Onze partners
                            </base-title>
                            <base-text accent="white">
                                Wij hebben de handen ineengeslagen met partners uit verschillende vakgebieden. Onderwijs, gezondheidsinstellingen, verzekeraars, recruiters en pensioenfondsen. Samen met onze partners ontketenen we een revolutie op de arbeidsmarkt.
                            </base-text>
                            <base-text accent="white">
                                Sta je achter ons initiatief en denk je een waardevolle bijdrage te kunnen leveren aan inkomenszekerheid? Sluit je nu aan.
                            </base-text>
                            <base-button :to="{ name: 'contact' }">
                                Neem contact op
                            </base-button>
                        </div>
                    </flex-column>
                </flex-columns>
            </div>
        </hero-gradient>
        <div class="container mx-auto my-32 px-4 lg:px-20">
            <div class="text-center mb-32 xl:px-72">
                <base-title size="md">
                    Vragen die we vaak krijgen
                </base-title>
                <base-text accent="light">
                    Het is op het eerste gezicht moeilijk te geloven dat zo'n initiatief echt mogelijk is. We verzekeren je: dat is het. Maar je zal vast nog wat vragen hebben. We hebben de meest gestelde vragen op een rijtje gezet.
                </base-text>
                <base-accordion active-item="one">
                    <accordion-item
                        name="one"
                        title="Kan dit binnen het huidige systeem?"
                        accent="dark"
                    >
                        Ja. Door werkenden en hun werk/inkomenszekerheid centraal
                        te stellen verandert er niks binnen de huidige wet- en
                        regelgeving. We gebruiken het simpelweg in ons voordeel.
                    </accordion-item>
                    <accordion-item
                        name="two"
                        title="Hoeveel kost het?"
                        accent="dark"
                    >
                        Het model is kostenneutraal. Dat betekent dat je alleen de
                        kosten kwijt bent die je al voor je werknemers betaalde.
                        We rekenen slechts 2% voor de uitvoeringskosten. Dit geld
                        wordt weer geïnvesteerd in de coöperatie en gebruikt in
                        gevallen van nood.
                    </accordion-item>
                    <accordion-item
                        name="three"
                        title="Wie draagt de kosten?"
                        accent="dark"
                    >
                        Om de verzekeringen betaalbaar te houden en de solidariteit
                        te borgen, doen we collectieve investering. Zo beschermen
                        we elkaar. We dragen de risico's als collectief. Op die
                        manier houden we de kosten laag.
                    </accordion-item>
                    <accordion-item
                        name="four"
                        title="Raak ik mijn werknemers kwijt?"
                        accent="dark"
                    >
                        Nee, je blijft werkgever. De administratieve zaken van
                        werkgeverschap worden slechts uit handen genomen, zodat
                        jij je kunt richten op waar je goed in bent. Je weet dat
                        je werknemers in goede handen zijn bij DeWerkgever.
                    </accordion-item>
                    <accordion-item
                        name="five"
                        title="Heeft de coöperatie een winstoogmerk?"
                        accent="dark"
                    >
                        Nee. Juist door het ontbreken van een winstoogmerk worden
                        de belangen van de leden echt behartigt. Door het
                        impactfonds kunnen we pech opvangen, investeren en voor
                        elkaar zorgen.
                    </accordion-item>
                </base-accordion>
            </div>
        </div>
        <div class="container mx-auto px-4 mb-20 lg:px-20">
            <div class="rounded-xl bg-white">
                <div class="text-center px-8 py-10 xl:px-72 xl:py-24">
                    <base-subtitle>Neem contact met ons op</base-subtitle>
                    <base-title size="md">
                        Nog meer vragen?
                    </base-title>
                    <base-text accent="light">
                        Kun je niet vinden waar je naar zoekt? Maak een praatje
                        met ons op één van onze events of stuur een berichtje.
                        We zijn benieuwd naar jouw vraag of feedback!
                    </base-text>
                    <base-button :to="{ name: 'contact' }">
                        Neem contact op
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeroGradient from '@/components/gradient/HeroGradient.vue';
    import BaseSubtitle from '@/components/typography/BaseSubtitle.vue';
    import BaseTitle from '@/components/typography/BaseTitle.vue';
    import BaseText from '@/components/typography/BaseText.vue';
    import ButtonList from '@/components/button/ButtonList.vue';
    import BaseButton from '@/components/button/BaseButton.vue';
    import BaseIcon from '@/components/icon/BaseIcon.vue';
    import FlexColumns from '@/components/columns/FlexColumns.vue';
    import FlexColumn from '@/components/columns/FlexColumn.vue';
    import LogoCloudItem from '@/components/logo-cloud/LogoCloudItem.vue';
    import BaseAccordion from '@/components/accordion/BaseAccordion.vue';
    import AccordionItem from '@/components/accordion/AccordionItem.vue';
    import ManifestPdf from '@/assets/documents/manifest-werken-aan-wendbaarheid.pdf';
    import Document2021 from '@/assets/documents/troonrede-2021.pdf';
    import Document2022 from '@/assets/documents/troonrede-2022.pdf';

    export default {
        name: 'about-page',
        components: {
            HeroGradient,
            BaseSubtitle,
            BaseTitle,
            BaseText,
            ButtonList,
            BaseButton,
            BaseIcon,
            FlexColumns,
            FlexColumn,
            LogoCloudItem,
            BaseAccordion,
            AccordionItem,
        },
        seo() {
            return {
                title: 'Over de coöperatie',
                keywords: 'coöperatie, de werkgever',
                description: 'Een zeker en duurzaam bestaan.',
            };
        },
        data() {
            return {
                ManifestPdf,
                Document2021,
                Document2022,
            };
        },
    };
</script>

export default {
    validations: {
        required: "Het {label} veld is vereist",
        requiredProp: "Het {label} veld is vereist",
        requiredOneOf: "Een van deze velden is vereist",
        requiredConfirm: "Je moet akkoord gaan met dit veld",
        email: "Het {label} veld moet een geldig e-mailadres zijn",
        max: "Het {label} veld moet minder dan {less} zijn",
        length: "Het {label} veld moet {length} karakters lang zijn",
        iban: "Het {label} veld moet een geldig IBAN zijn",
        file: "Het {label} veld moet een van de volgende bestandtypes bevatten: {mimes}",
        minValue: "Het {label} veld moet minimaal {min} zijn",
        maxValue: "Het {label} veld mag maximaal {max} zijn",
        isUnique: "Het {label} veld moet een unieke waarde zijn",
        bsn: "Het {label} veld moet een geldig bsn zijn",
        minLength: "Het {label} veld moet minimaal {min} tekens lang zijn.",
        maxLength: "Het {label} veld mag maximaal {max} tekens lang zijn.",
        sameAs: "Het {label} veld moet hetzelfde zijn als het {equalTo} veld.",
        mimes: "Het {label} veld moet een van de volgende bestandtypes bevatten: {mimes}",
    },
};

<template>
    <div class="flex" :class="[directionClasses]">
        <div class="flex justify-center items-center rounded-full w-12 h-12 bg-primary-500 text-primary-50">
            <base-icon :icon="icon" size="xl"></base-icon>
        </div>
        <div class="flex flex-col flex-1" :class="[itemContentClasses]">
            <base-title
                class="mt-[10px]"
                size="sm"
                accent="gray"
            >
                {{ title }}
            </base-title>
            <base-text accent="light">
                <slot></slot>
            </base-text>
        </div>
    </div>
</template>

<script>
    import BaseIcon from '@/components/icon/BaseIcon.vue';
    import BaseTitle from '@/components/typography/BaseTitle.vue';
    import BaseText from '@/components/typography/BaseText.vue';

    export default {
        name: 'icon-list-item',
        components: {
            BaseIcon,
            BaseTitle,
            BaseText,
        },
        props: {
            icon: {
                type: [Array, String],
                default: '',
            },
            title: {
                type: String,
                default: '',
            },
            direction: {
                type: String,
                default: 'horizontal',
            },
        },
        computed: {
            directionClasses() {
                const directionMap = {
                    horizontal: ['items-start', 'gap-6'],
                    vertical: ['flex-col', 'items-center', 'gap-2'],
                };

                return directionMap[this.direction];
            },
            itemContentClasses() {
                const paddingMap = {
                    horizontal: [],
                    vertical: ['px-10', 'text-center', 'xl:px-4'],
                };

                return paddingMap[this.direction];
            },
        },
    };
</script>

<template>
    <div>
        <div class="container mx-auto px-4 py-32">
            <base-title>Pagina niet gevonden...</base-title>
            <base-text accent="light">
                De opgevraagde pagina kon helaas niet worden gevonden.
            </base-text>
        </div>
    </div>
</template>

<script>
    import BaseTitle from '@/components/typography/BaseTitle.vue';
    import BaseText from '@/components/typography/BaseText.vue';

    export default {
        name: 'error-page',
        components: {
            BaseTitle,
            BaseText,
        },
    };
</script>

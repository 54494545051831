import {
    mapKeys,
    camelCase,
} from 'lodash-es';

export function camelCaseKeys(object) {
    return mapKeys(object, (value, key) => {
        return camelCase(key);
    });
}

export function route(url) {
    return `${import.meta.env.VITE_API_ENDPOINT}${url}`;
}

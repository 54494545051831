import { createI18n } from 'vue-i18n';

const dutchModulesJS = import.meta.glob('./nl/*.js', { eager: true });
const englishModulesJS = import.meta.glob('./en/*.js', { eager: true });
const dutchModulesJSON = import.meta.glob('./nl/*.json', { eager: true });
const englishModulesJSON = import.meta.glob('./en/*.json', { eager: true });

function getTranslationFiles(modules) {
    return Object.keys(modules).reduce((prev, path) => {
        const key = Object.keys(modules[path].default)[0];

        prev[key] = modules[path].default[key];

        return prev;
    }, {});
}

const i18n = createI18n({
    locale: 'nl',
    fallbackLocale: 'en',
    messages: {
        nl: {
            ...getTranslationFiles(dutchModulesJS),
            ...getTranslationFiles(dutchModulesJSON),
        },
        en: {
            ...getTranslationFiles(englishModulesJS),
            ...getTranslationFiles(englishModulesJSON),
        },
    },
});

export default i18n;

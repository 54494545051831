<template>
    <div>
        <hero-gradient class="xl:pt-24" :clip-bottom="true">
            <img
                class="hidden absolute inset-0 w-full pointer-events-none xl:block"
                src="@/assets/shapes/bg-shapes.png"
            >
            <div class="relative z-10 container mx-auto px-4 pt-24 lg:pt-48 xl:px-40">
                <flex-columns>
                    <flex-column
                        sm="12"
                        lg="12"
                        xl="9"
                    >
                        <base-title
                            tag="h1"
                            accent="white"
                            spacing="lg"
                            class="pr-8"
                        >
                            De beste werkgever brengt rust, veiligheid en een zeker inkomen.
                        </base-title>
                        <base-text accent="primary">
                            De missie van coöperatie <em>De</em>Werkgever is om dat te ondersteunen.
                        </base-text>
                        <base-text accent="primary">
                            Gun iedereen een vast contract met inkomenszekerheid.
                            Als collectief van werkenden zorgen we ervoor dat we ons kunnen
                            aanpassen en verzekerd zijn van inkomen, scholing en werk in een
                            onvoorspelbare wereld.
                        </base-text>
                    </flex-column>
                    <flex-column sm="12">
                        <base-title
                            class="text-center mt-6 xl:mt-24"
                            size="xs"
                            spacing="xs"
                            accent="white"
                        >
                            Mede mogelijk gemaakt door:
                        </base-title>
                    </flex-column>
                </flex-columns>
            </div>
            <div
                class="
                    relative
                    p-6
                    after:absolute
                    after:inset-0
                    after:pointer-events-none
                    after:bg-black/10
                    xl:px-36
                "
            >
                <div
                    class="
                        grid
                        grid-flow-col
                        auto-cols-[27%]
                        gap-4
                        overflow-auto
                        scrollbar-hidden
                        lg:auto-cols-[9%]
                        lg:gap-4
                    "
                >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/wage-indicator.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/verloning.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/abnamro.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/aig.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/saltagroup.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/goodhabitz.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/academie-werkend-leren.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/ig.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/digitale-fitheid.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/factris.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/fast-forward-group.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/goed.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/ministerie.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/salarise.svg"
                        width="100"
                    >
                    <img
                        class="mx-auto"
                        src="@/assets/partners/werkvereniging.svg"
                        width="100"
                    >
                </div>
            </div>
        </hero-gradient>
        <div class="container px-4 pt-24 mx-auto 2xl:px-48">
            <base-title class="text-center lg:px-20 lg:mb-20" size="md">
                Lees hier wat coöperatie “DeWerkgever”
                voor jou kan betekenen
            </base-title>
            <scrollable-list>
                <base-card
                    class="flex-1"
                    image="assets/photos/manager-talking-to-interns.jpeg"
                    title="Voor werkgevers"
                >
                    <base-text :has-spacing="false">
                        Jij wil de beste mensen aantrekken en behouden.
                        Maar ook wendbaar blijven in een veranderende wereld.
                        Gun jij je mensen ook een vast contract met inkomensgarantie?
                        Het liefst zonder de risico's en het gedoe?
                    </base-text>
                    <base-text>
                        <strong>Wij maken jou de beste werkgever.</strong>
                    </base-text>

                    <template #action>
                        <base-button :to="{ name: 'employers' }">
                            Dit wil ik ook!
                        </base-button>
                    </template>
                </base-card>
                <base-card
                    class="flex-1"
                    image="assets/photos/business-people-architects-having-discussion.jpeg"
                    title="Voor werkenden"
                >
                    <base-text>
                        Je kunt altijd op een inkomen rekenen bij coöperatie DeWerkgever.
                        Samen voorkomen we dat je bewust of onbewust jouw geluk en toekomst
                        in de weg staat. Zo krijg jij de kans om altijd te werken
                        in een baan die het beste bij je past.
                        <strong>Wij gunnen jou het beste.</strong>
                    </base-text>

                    <template #action>
                        <base-button :to="{ name: 'workers' }">
                            Hoe werkt dat?
                        </base-button>
                    </template>
                </base-card>
                <base-card
                    class="flex-1"
                    image="assets/photos/group-of-young-businesspeople-standing-outdoors.jpeg"
                    title="Over coöperatie DeWerkgever"
                >
                    <base-text>
                        DeWerkgever is een Werkenden coöperatie zonder winstoogmerk.
                        We helpen werkgevers door haar werkenden te verenigen in
                        een collectief en risico's te minimaliseren.
                        Door kostenneutraal en vroegtijdig te investeren in
                        elkaar zorgen we ervoor dat iedereen economisch
                        zelfstandig wordt, is en blijft.
                    </base-text>

                    <template #action>
                        <base-button :to="{name: 'employers' }">
                            Hoe werkt dat?
                        </base-button>
                    </template>
                </base-card>
            </scrollable-list>
        </div>
        <hero-gradient class="py-28 mt-24" :clip-both="true">
            <div class="relative container px-4 mx-auto lg:px-16 lg:py-20 xl:px-40">
                <img
                    class="absolute inset-0 w-full pointer-events-none"
                    src="@/assets/shapes/hero-bg.svg"
                >
                <div
                    class="
                        flex
                        flex-col
                        items-center
                        justify-center
                        lg:flex-row
                        lg:space-x-4
                        lg:mb-20
                    "
                >
                    <base-title accent="white" size="md">
                        Waarom coöperatie
                    </base-title>
                    <div class="flex justify-center items-center mb-12 lg:mb-0">
                        <img
                            class="w-[250px] lg:w-[348px]"
                            src="@/assets/logo/logo-white.svg"
                            width="250"
                        >
                        <base-title
                            spacing="none"
                            accent="white"
                            size="lg"
                        >
                            ?
                        </base-title>
                    </div>
                </div>
                <div class="flex flex-col xl:px-24">
                    <image-box
                        title="Inkomenszekerheid"
                        image="assets/photos/young-businessman-with-coffee-to-go.jpeg"
                    >
                        <base-text accent="white">
                            Wat is inkomenszekerheid eigenlijk? Het is simpelweg
                            de garantie dat jij in je behoefte kan voorzien. Door
                            een voorspelbaar inkomen, ook als je (even) niet kunt werken.
                            Oftewel: zekerheid nu, tijdens je werkende leven én
                            erna het goed geregeld hebben.
                        </base-text>
                        <base-text accent="white">
                            Met coöperatie DeWerkgever hoef je je geen zorgen
                            meer te maken om je financiële situatie.
                        </base-text>
                    </image-box>
                    <image-box
                        title="Gezondheid"
                        image="assets/photos/tourist-couple-traveling-travel-walking.jpeg"
                    >
                        <base-text accent="white">
                            <strong>Doe werk waar je blij van wordt.</strong>
                            Met de ruimte om de verstandige keuze te maken, nu en
                            in de toekomst. Om een gezonde loopbaan te garanderen,
                            investeren we vooral bij fysiek of mentaal zware
                            beroepen preventief in de juiste skills. Zo verminderen
                            we eventuele risico's en kosten. Dat is duurzaam. En
                            als het toch mis gaat hebben we het ook uitstekend geregeld!
                        </base-text>
                    </image-box>
                    <image-box
                        title="Skills"
                        image="assets/photos/collaboration-and-analysis-by-business-people.jpeg"
                    >
                        <base-text accent="white">
                            <strong>Een leven lang ontwikkelen, fatsoenlijk geregeld.</strong>
                            Je wordt ondersteund tijdens je baan. Wanneer je nieuwe
                            skills moet leren, of een andere baan wil zoeken en
                            moet omscholen. Coöperatie DeWerkgever helpt je succesvol
                            te blijven, zelfs als het even tegen zit. Zo hoort het
                            te zijn: de werkende in de regie. <br>
                            Een leven lang leren, zo werken we samen aan jouw succes.
                        </base-text>
                    </image-box>
                    <image-box
                        title="Impact"
                        image="assets/photos/group-of-young-businesspeople-using-laptop-outdoor.jpeg"
                        :has-border="false"
                    >
                        <base-text accent="white">
                            <strong>Fatsoenlijk werknemerschap begint met goed werkgeverschap.</strong>
                            Ondersteun een collectief met je bedrijf. In een
                            collectief kunnen we met elkaar onzekerheid verminderen.
                            Zonder zorgen om inkomen, een goede gezondheid en de
                            juiste skills kun je pas echt impact maken. Coöperatie
                            DeWerkgever zorgt voor professioneel werkgeverschap
                            en stelt het geluk van de werkenden centraal.
                        </base-text>
                    </image-box>
                </div>
            </div>
        </hero-gradient>
        <div class="container mx-auto px-4 pt-24 lg:px-16">
            <div class="text-center lg:px-32 lg:mb-16">
                <base-subtitle>In het kort</base-subtitle>
                <base-title size="md">
                    Met elkaar, voor elkaar
                </base-title>
                <base-text accent="light">
                    Er verandert vrij weinig in je dagelijkse leven als je onderdeel
                    bent van coöperatie DeWerkgever. Je werkt en doet je ding, maar
                    dan zonder zorgen. Geen spanning over inkomensverlies door ziekte,
                    contractverleningen of pech. <strong>Jij hebt de regie</strong> over je carrière.
                    Ondersteund door professionele partners in een collectief,
                    bij een werkgever die het beste met je voor heeft.
                </base-text>
                <base-button :to="{ name: 'contact' }">
                    Meer weten?
                </base-button>
            </div>
            <flex-columns>
                <flex-column sm="12" lg="4">
                    <img
                        src="@/assets/photos/portrait-of-woman-at-city-street.jpeg"
                        class="my-12 rounded-xl lg:my-0"
                        alt=""
                    >
                </flex-column>
                <flex-column sm="12" lg="8">
                    <icon-list>
                        <icon-list-item
                            title="Fatsoenlijk werkgeverschap"
                            :icon="['far', 'comments']"
                        >
                            Een goede werkgever krijgt en behoudt gemakkelijk
                            personeel. Naast interessant werk, betaal je je
                            werknemers naar waarde en zijn de zekerheden goed
                            verzorgd. Goed werkgeverschap geef je het beste
                            vorm met coöperatie DeWerkgever. Wij zijn
                            gespecialiseerd in fatsoenlijk werkgeverschap.
                        </icon-list-item>
                        <icon-list-item
                            title="Fatsoenlijk werkgeverschap"
                            :icon="['far', 'bolt']"
                        >
                            Bij coöperatie <em>De</em>Werkgever zijn alle ingrediënten aanwezig
                            om je als werkende zorgeloos te kunnen focussen op je werk.
                            Collectief zorgen we voor elkaar. We werken preventief aan
                            goede gezondheid en de juiste skills om je loop baan
                            duurzaam vorm te geven. Nu, en later.
                        </icon-list-item>
                    </icon-list>
                </flex-column>
            </flex-columns>
            <div
                class="
                    flex
                    flex-col
                    py-10
                    px-[30px]
                    rounded-[10px]
                    my-10
                    bg-white
                    lg:px-16
                    lg:py-10
                    lg:my-32
                "
            >
                <base-title size="sm" accent="gray">
                    “Een balletdanser moet vanwege de zware fysieke eisen ver
                    voor de pensioenleeftijd de loopbaan al een andere wending
                    geven. Waarom wachten we met andere zware beroepen, zoals
                    de stratenmaker, tot hij of zij neervalt?”
                </base-title>
                <div class="flex items-start">
                    <img
                        src="@/assets/testimonials/edward.png"
                        width="60"
                        height="60"
                        class="mr-4"
                        alt=""
                    >
                    <div class="flex flex-col justify-around">
                        <base-text accent="dark" :has-spacing="false">
                            Edward Belgraver
                        </base-text>
                        <base-text class="font-semibold" accent="primary-dark">
                            Initiatiefnemer
                        </base-text>
                    </div>
                </div>
            </div>
        </div>
        <hero-gradient :clip-both="true">
            <div class="container mx-auto px-4 lg:px-16">
                <flex-columns class="lg:flex-row-reverse">
                    <flex-column sm="12" lg="6">
                        <div class="flex justify-center -mx-1 mt-20 mb-16 lg:py-20">
                            <div class="flex flex-col justify-center -mt-20">
                                <logo-cloud-item image="assets/partners/saltagroup.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/digitale-fitheid.svg"></logo-cloud-item>
                            </div>
                            <div class="flex flex-col justify-center">
                                <logo-cloud-item image="assets/partners/academie-werkend-leren.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/ministerie.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/ig.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/fast-forward-group.svg"></logo-cloud-item>
                            </div>
                            <div class="flex flex-col justify-center">
                                <logo-cloud-item image="assets/partners/verloning.svg"></logo-cloud-item>
                                <logo-cloud-item :is-empty="true"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/factris.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/goodhabitz.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/salarise.svg"></logo-cloud-item>
                            </div>
                            <div class="flex flex-col justify-center mt-4">
                                <logo-cloud-item image="assets/partners/abnamro.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/werkvereniging.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/aig.svg"></logo-cloud-item>
                            </div>
                            <div class="flex flex-col justify-center mt-12">
                                <logo-cloud-item image="assets/partners/goed.svg"></logo-cloud-item>
                                <logo-cloud-item image="assets/partners/wage-indicator.svg"></logo-cloud-item>
                            </div>
                        </div>
                    </flex-column>
                    <flex-column sm="12" lg="6">
                        <div class="text-center mb-36 lg:mb-0 lg:py-36 lg:text-left">
                            <base-title accent="white" size="md">
                                Onze partners
                            </base-title>
                            <base-text accent="white">
                                Wij hebben de handen ineengeslagen met partners uit verschillende vakgebieden. Onderwijs, gezondheidsinstellingen, verzekeraars, recruiters en pensioenfondsen. Samen met onze partners ontketenen we een revolutie op de arbeidsmarkt.
                            </base-text>
                            <base-text accent="white">
                                Sta je achter ons initiatief en denk je een waardevolle bijdrage te kunnen leveren aan inkomenszekerheid? Sluit je nu aan.
                            </base-text>
                            <base-button :to="{ name: 'contact' }">
                                Neem contact op
                            </base-button>
                        </div>
                    </flex-column>
                </flex-columns>
            </div>
        </hero-gradient>
        <!-- Events -->
        <div class="my-48"></div>
    </div>
</template>

<script>
    import HeroGradient from '@/components/gradient/HeroGradient.vue';
    import BaseTitle from '@/components/typography/BaseTitle.vue';
    import BaseText from '@/components/typography/BaseText.vue';
    import FlexColumns from '@/components/columns/FlexColumns.vue';
    import FlexColumn from '@/components/columns/FlexColumn.vue';
    import ScrollableList from '@/components/list/ScrollableList.vue';
    import BaseCard from '@/components/card/BaseCard.vue';
    import BaseButton from '@/components/button/BaseButton.vue';
    import ImageBox from '@/components/box/ImageBox.vue';
    import BaseSubtitle from '@/components/typography/BaseSubtitle.vue';
    import IconList from '@/components/list/IconList.vue';
    import IconListItem from '@/components/list/IconListItem.vue';
    import LogoCloudItem from '@/components/logo-cloud/LogoCloudItem.vue';

    export default {
        name: 'home-page',
        components: {
            HeroGradient,
            BaseTitle,
            BaseText,
            FlexColumns,
            FlexColumn,
            ScrollableList,
            BaseCard,
            BaseButton,
            ImageBox,
            BaseSubtitle,
            IconList,
            IconListItem,
            LogoCloudItem,
        },
        seo() {
            return {
                title: 'Home',
                keywords: 'dewerkgever, inkomenszekerheid',
                description: 'Gun iedereen een vast contract met inkomenszekerheid.',
            };
        },
    };
</script>

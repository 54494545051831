<template>
    <div>
        <hero-gradient class="text-center py-24 xl:py-48" :clip-bottom="true">
            <div class="container mx-auto px-4 lg:px-20 xl:px-72">
                <base-subtitle accent="secondary">
                    Contact
                </base-subtitle>
                <base-title
                    class="lg:!text-5xl"
                    size="sm"
                    accent="white"
                >
                    Neem contact op
                </base-title>
                <base-text accent="white">
                    Ben je enthousiast geworden over DeWerkgever en wil je
                    vrijblijvend met ons in gesprek? Ook als je even wil
                    filosoferen, sparren, of alles wil weten over onze idealen.
                    <strong>We praten graag met je.</strong>
                </base-text>
            </div>
        </hero-gradient>
        <div class="container mx-auto px-4 mb-12 lg:px-20 xl:mb-28">
            <flex-columns class="xl:flex-row-reverse">
                <flex-column sm="12" xl="6">
                    <img
                        class="relative rounded-xl -top-16 xl:-top-32"
                        src="@/assets/photos/way-to-work-man-texting-on-phone.jpeg"
                        alt=""
                    >
                </flex-column>
                <flex-column sm="12" xl="6">
                    <div class="relative -top-16 xl:-top-32 rounded-xl bg-white">
                        <div class="px-5 py-8">
                            <contact-form :has-message-field="true"></contact-form>
                        </div>
                    </div>
                </flex-column>
            </flex-columns>
        </div>
    </div>
</template>

<script>
    import HeroGradient from '@/components/gradient/HeroGradient.vue';
    import BaseSubtitle from '@/components/typography/BaseSubtitle.vue';
    import BaseTitle from '@/components/typography/BaseTitle.vue';
    import BaseText from '@/components/typography/BaseText.vue';
    import FlexColumns from '@/components/columns/FlexColumns.vue';
    import FlexColumn from '@/components/columns/FlexColumn.vue';
    import ContactForm from '@/modules/contact/ContactForm.vue';

    export default {
        name: 'contact-page',
        components: {
            HeroGradient,
            BaseSubtitle,
            BaseTitle,
            BaseText,
            FlexColumns,
            FlexColumn,
            ContactForm,
        },
        seo() {
            return {
                title: 'Contact',
                keywords: 'contact',
                description: 'Neem contact op met DeWerkgever.',
            };
        },
    };
</script>

<template>
    <p class="font-sans text-base lg:text-xl" :class="[accentClasses, spacingClasses]">
        <slot></slot>
    </p>
</template>

<script>
    export default {
        name: 'base-text',
        props: {
            accent: {
                type: String,
                default: 'base',
            },
            hasSpacing: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            accentClasses() {
                const accentMap = {
                    primary: ['text-primary-50'],
                    'primary-light': ['text-primary-200'],
                    'primary-dark': ['text-primary-500'],
                    white: ['text-white'],
                    lightest: ['text-gray-50'],
                    light: ['text-gray-500'],
                    dark: ['text-gray-600'],
                    base: ['text-black'],
                };

                return accentMap[this.accent];
            },
            spacingClasses() {
                if (!this.hasSpacing) {
                    return [];
                }

                return [
                    'mb-9',
                    'last:mb-0',
                ];
            },
        },
    };
</script>

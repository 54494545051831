<template>
    <div>
        <hero-gradient class="text-center py-24 xl:py-48" :clip-bottom="true">
            <div class="container mx-auto px-4 lg:px-20 xl:px-72">
                <base-subtitle accent="secondary">
                    Voor werkgevers
                </base-subtitle>
                <base-title
                    class="lg:!text-5xl"
                    size="sm"
                    accent="white"
                >
                    Jouw partner voor fatsoenlijk werkgeverschap.
                </base-title>
                <base-text accent="white">
                    <strong>Focus op waar je goed in bent.</strong>
                    Jij wil een fatsoenlijke werkgever zijn. Gun je mensen vanaf
                    dag één een vast contract met inkomenszekerheid én minimaliseer
                    je risico's. Samen worden we risicodrager voor de
                    werknemersverzekeringen. Zo blijf jij wendbaar bij veranderende
                    omstandigheden. En krijg en houdt je de beste mensen.
                </base-text>
            </div>
        </hero-gradient>
        <div class="container mx-auto px-4 mb-12 lg:px-20 xl:mb-28">
            <img
                class="relative rounded-xl -top-16"
                src="@/assets/photos/collaboration-and-analysis-by-business-people-work.jpeg"
                alt=""
            >
            <flex-columns>
                <flex-column sm="12" xl="6">
                    <base-subtitle>Jouw situatie</base-subtitle>
                    <base-title size="md" accent="gray">
                        Herken je deze uitdagingen?
                    </base-title>
                    <base-text accent="light">
                        <strong>Zorgeloos samenwerken aan de toekomst.</strong>
                        Vanzelfsprekend gun jij je werknemers het beste: een leuke
                        baan en een goed inkomen. Maar je moet ook een bedrijf runnen
                        en wil liever geen onverantwoorde risico's nemen. Je gunt je
                        mensen een vast contract, maar met twee jaar doorbetaling bij
                        ziekte, is dat best eng. Los je dat op door maar met
                        freelancers, tijdelijke contracten of intermediairs te werken?
                        Herken je je in één van deze problemen?
                        <strong>Samen lossen we het op.</strong>
                    </base-text>
                </flex-column>
                <flex-column sm="12" xl="6">
                    <scrollable-list :desktop="true">
                        <image-card
                            title="Overzie jij de papieren tijger van wet- en regelgeving?"
                            image="assets/photos/rear-view-of-businesswoman.jpeg"
                        >
                            Samen zorgen we voor een samenwerking gebaseerd op waarde en vertrouwen.
                        </image-card>
                        <image-card
                            title="Je wil wel zekerheid beloven, maar dat lukt niet altijd."
                            image="assets/photos/portrait-of-woman-at-city-street.jpeg"
                        >
                            Gun iedereen een vast contract met slechts 2 maanden eigen risico.
                        </image-card>
                        <image-card
                            title="Loyaliteit en vertrouwen verloren in de samenwerking?"
                            image="assets/photos/portrait-of-young-businesspeople-with-laptop.jpeg"
                        >
                            Bij coöperatie DeWerkgever staat er een collectief achter je.
                        </image-card>
                        <image-card
                            title="Lopen je mensen op hun tandvlees?"
                            image="assets/photos/girl-driver-feeling-doubtful.jpeg"
                        >
                            Zorg voor een gezonde loopbaan met DeWerkgever.
                        </image-card>
                        <image-card
                            title="Te weinig doorgroei in je organisatie?"
                            image="assets/photos/group-of-young-businesspeople-standing-outdoors.jpeg"
                        >
                            Bij ons is een leven lang leren de norm. Zo blijven je mensen kansrijk.
                        </image-card>
                        <image-card
                            title="Loop je hier ook tegenaan?"
                            image="assets/photos/portrait-of-a-serious-business-lady.jpeg"
                        >
                            <template #action>
                                <base-button :to="{ name: 'contact' }">
                                    Neem contact op
                                </base-button>
                            </template>
                        </image-card>
                    </scrollable-list>
                </flex-column>
            </flex-columns>
        </div>
        <div class="container mx-auto px-4 mb-12 lg:px-20 xl:mb-20">
            <flex-columns class="xl:flex-row-reverse">
                <flex-column sm="12" xl="6">
                    <base-subtitle>Waarom coöperatie DeWerkgever</base-subtitle>
                    <base-title accent="gray" size="md">
                        Ons doel: bedrijven helpen de beste werkgever te zijn.
                    </base-title>
                    <base-text accent="light">
                        <strong>
                            Van een wirwar aan contracten blijft er één over: een
                            'vast contract met inkomenszekerheid'.
                        </strong>
                    </base-text>
                    <base-text accent="light">
                        Jouw bedrijf, maar ook de werkenden, moeten wendbaar zijn bij
                        veranderende omstandigheden. Maar dit mag niet ten koste gaan
                        van elkaars bestaanszekerheid. Daarom krijgt iedereen een vast
                        contract mét inkomenszekerheid als je met coöperatie
                        DeWerkgever werkt. Er staat een grote groep professionele
                        partijen achter je om jou zorgeloos te laten doen waar jij
                        goed in bent.
                    </base-text>
                    <base-text accent="light">
                        Door vanuit een eigen white label collectief te werken, bouwen
                        we aan moderne solidariteit. Het risico op arbeid beperken we
                        tot slechts twee maanden, bij ziekte én ontslag voor jou. De
                        werkenden krijgen van de coöperatie inkomenszekerheid tot, en
                        met behoud van pensioen. Zo werkt jouw bedrijf duurzaam en
                        wendbaar samen.
                    </base-text>
                </flex-column>
                <flex-column sm="12" xl="6">
                    <img
                        class="flex-1"
                        src="@/assets/photos/photo-grid-employer.png"
                        alt=""
                    >
                </flex-column>
            </flex-columns>
        </div>
        <div class="container mx-auto px-4 mb-12 lg:px-20 xl:mb-20">
            <flex-columns>
                <flex-column sm="12" xl="4">
                    <icon-list-item
                        title="Lage risico's"
                        direction="vertical"
                        :icon="['far', 'triangle-exclamation']"
                    >
                        Slechts twee maanden eigen risico bij ziekte en ontslag.
                        Geen transitievergoedingen, kantonrechtformules of dure
                        conflicten meer. En heeft iemand wel inkomensverlies? Dan
                        ook geen extra kosten. Duidelijk en eerlijk.
                    </icon-list-item>
                </flex-column>
                <flex-column sm="12" xl="4">
                    <icon-list-item
                        title="Kostenneutraal"
                        direction="vertical"
                        :icon="['far', 'circle-minus']"
                    >
                        Wanneer je je aansluit bij de coöperatie kost dit niets
                        extra. We gebruiken dezelfde kosten (18%) om fondsen op te
                        bouwen en te grote risico's te verzekeren. Voor de hele
                        uitvoering brengen we slechts 2% in rekening.
                    </icon-list-item>
                </flex-column>
                <flex-column sm="12" xl="4">
                    <icon-list-item
                        title="Fatsoenlijk werkgeverschap"
                        direction="vertical"
                        :icon="['far', 'building']"
                    >
                        Goed werkgeverschap is verantwoord werkgeverschap. Voor
                        het vaste contract met inkomenszekerheid werken we samen
                        met professionele partners. Wij helpen jou de beste
                        werkgever te worden.
                    </icon-list-item>
                </flex-column>
                <flex-column sm="12" xl="4">
                    <icon-list-item
                        title="Gezondheid"
                        direction="vertical"
                        :icon="['far', 'heart']"
                    >
                        Een stratenmaker haalt vermoedelijk zijn pensioen niet.
                        En dat is niet het enige voorbeeld. Het is belangrijk dat
                        iedereen gezond kan blijven werken. Door preventieve zorg
                        en bijscholing werken we aan duurzame inzetbaarheid.
                        Zo verminderen we met elkaar de risico's.
                    </icon-list-item>
                </flex-column>
                <flex-column sm="12" xl="4">
                    <icon-list-item
                        title="Juiste skills"
                        direction="vertical"
                        :icon="['far', 'graduation-cap']"
                    >
                        Samen werken aan een mooie toekomst.
                        Met de juiste skills zijn mensen beter in wat ze doen.
                        Zo onstaan er ook minder klachten door werk. Door met data
                        en assessments kennisverlies te voorkomen blijven alle
                        werkenden die onderdeel zijn van coöperatie DeWerkgever
                        altijd kansrijk.
                    </icon-list-item>
                </flex-column>
                <flex-column sm="12" xl="4">
                    <icon-list-item
                        title="Impactvol"
                        direction="vertical"
                        :icon="['far', 'earth-europe']"
                    >
                        Met DeWerkgever maak je de meeste impact op jezelf en je
                        mensen. Je kunt als bedrijf de beste mensen krijgen wanneer
                        je vanaf dag één een vast contract met inkomenszekerheid
                        aanbiedt. Je bent ook wendbaar als het even tegen zit. Gun
                        jezelf en je werknemers coöperatie DeWerkgever.
                    </icon-list-item>
                </flex-column>
            </flex-columns>
        </div>
        <hero-gradient class="text-center py-36" :clip-both="true">
            <div class="container mx-auto px-4 lg:px-20 xl:px-72">
                <base-title accent="white" size="md">
                    Vragen die we vaak krijgen
                </base-title>
                <base-text accent="lightest">
                    Het is op het eerste gezicht moeilijk te geloven dat zo'n
                    initiatief echt mogelijk is. We verzekeren je: dat is het.
                    Maar je zal vast nog wat vragen hebben. We hebben de meest
                    gestelde vragen op een rijtje gezet.
                </base-text>
                <base-accordion active-item="one">
                    <accordion-item name="one" title="Kan dit binnen het huidige systeem?">
                        Ja. Door werkenden en hun werk/inkomenszekerheid centraal
                        te stellen verandert er niks binnen de huidige wet- en
                        regelgeving. We gebruiken het simpelweg in ons voordeel.
                    </accordion-item>
                    <accordion-item name="two" title="Hoeveel kost het?">
                        Het model is kostenneutraal. Dat betekent dat je alleen de
                        kosten kwijt bent die je al voor je werknemers betaalde.
                        We rekenen slechts 2% voor de uitvoeringskosten. Dit geld
                        wordt weer geïnvesteerd in de coöperatie en gebruikt in
                        gevallen van nood.
                    </accordion-item>
                    <accordion-item name="three" title="Wie draagt de kosten?">
                        Om de verzekeringen betaalbaar te houden en de solidariteit
                        te borgen, doen we collectieve investering. Zo beschermen
                        we elkaar. We dragen de risico's als collectief. Op die
                        manier houden we de kosten laag.
                    </accordion-item>
                    <accordion-item name="four" title="Raak ik mijn werknemers kwijt?">
                        Nee, je blijft werkgever. De administratieve zaken van
                        werkgeverschap worden slechts uit handen genomen, zodat
                        jij je kunt richten op waar je goed in bent. Je weet dat
                        je werknemers in goede handen zijn bij DeWerkgever.
                    </accordion-item>
                    <accordion-item name="five" title="Heeft de coöperatie een winstoogmerk?">
                        Nee. Juist door het ontbreken van een winstoogmerk worden
                        de belangen van de leden echt behartigt. Door het
                        impactfonds kunnen we pech opvangen, investeren en voor
                        elkaar zorgen.
                    </accordion-item>
                </base-accordion>
            </div>
        </hero-gradient>
        <div class="container mx-auto px-4 lg:px-20">
            <div class="relative rounded-xl -top-16 bg-white">
                <div class="text-center px-8 py-10 xl:px-72 xl:py-24">
                    <base-subtitle>Neem contact met ons op</base-subtitle>
                    <base-title size="md">
                        Nog meer vragen?
                    </base-title>
                    <base-text accent="light">
                        Kun je niet vinden waar je naar zoekt? Maak een praatje
                        met ons op één van onze events of stuur een berichtje.
                        We zijn benieuwd naar jouw vraag of feedback!
                    </base-text>
                </div>
                <div class="px-5 pb-10 xl:px-72">
                    <contact-form></contact-form>
                </div>
            </div>
            <div class="text-center mb-32 xl:px-72">
                <base-title size="md">
                    Ben je nog niet uitgelezen?
                </base-title>
                <base-text accent="light">
                    We werken op dit moment hard aan de overeenkomsten die exact
                    vertellen hoe alles werkt. We hebben een manifest geschreven
                    waarin we ons idee uitleggen. Daar staat onze visie in
                    beschreven en dat delen we graag met jou. Wij zetten ons in
                    voor een gezonde en duurzame arbeidsmarkt. <br>
                    Doe jij mee?
                </base-text>
                <base-button
                    download
                    accent="white"
                    :href="ManifestPdf"
                >
                    <template #icon>
                        <base-icon :icon="['far', 'download']"></base-icon>
                    </template>
                    Download
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
    import HeroGradient from '@/components/gradient/HeroGradient.vue';
    import BaseSubtitle from '@/components/typography/BaseSubtitle.vue';
    import BaseTitle from '@/components/typography/BaseTitle.vue';
    import BaseText from '@/components/typography/BaseText.vue';
    import ScrollableList from '@/components/list/ScrollableList.vue';
    import ImageCard from '@/components/card/ImageCard.vue';
    import IconListItem from '@/components/list/IconListItem.vue';
    import BaseAccordion from '@/components/accordion/BaseAccordion.vue';
    import AccordionItem from '@/components/accordion/AccordionItem.vue';
    import BaseButton from '@/components/button/BaseButton.vue';
    import BaseIcon from '@/components/icon/BaseIcon.vue';
    import FlexColumns from '@/components/columns/FlexColumns.vue';
    import FlexColumn from '@/components/columns/FlexColumn.vue';
    import ContactForm from '@/modules/contact/ContactForm.vue';
    import ManifestPdf from '@/assets/documents/manifest-werken-aan-wendbaarheid.pdf';

    export default {
        name: 'employers-page',
        components: {
            HeroGradient,
            BaseSubtitle,
            BaseTitle,
            BaseText,
            ScrollableList,
            ImageCard,
            IconListItem,
            BaseAccordion,
            AccordionItem,
            BaseButton,
            BaseIcon,
            FlexColumns,
            FlexColumn,
            ContactForm,
        },
        seo() {
            return {
                title: 'Voor werkgevers',
                keywords: 'dewerkgever, vast contract, werkgevers',
                description: 'Jouw partner voor fatsoenlijk werkgeverschap.',
            };
        },
        data() {
            return {
                ManifestPdf,
            };
        },
    };
</script>

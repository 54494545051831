<template>
    <div
        class="
            relative
            overflow-hidden
            rounded-lg
            before:absolute
            before:inset-0
            before:bg-gradient-to-b
            before:from-transparent
            before:via-black/20
            before:to-black/70
        "
    >
        <img
            class="h-[400px] object-cover w-full"
            alt=""
            :src="imageUrl"
        >
        <div class="absolute bottom-0 py-8 px-10">
            <base-title accent="white" size="sm">
                {{ title }}
            </base-title>
            <base-text accent="white">
                <slot></slot>
            </base-text>
            <slot name="action"></slot>
        </div>
    </div>
</template>

<script>
    import BaseTitle from '@/components/typography/BaseTitle.vue';
    import BaseText from '@/components/typography/BaseText.vue';

    export default {
        name: 'image-card',
        components: {
            BaseTitle,
            BaseText,
        },
        props: {
            image: {
                type: String,
                default: '',
            },
            title: {
                type: String,
                default: '',
            },
        },
        computed: {
            imageUrl() {
                return new URL(
                    `/src/${this.image}`,
                    import.meta.url,
                ).href;
            },
        },
    };
</script>

<template>
    <div
        class="flex flex-wrap -mb-2 children:mr-2 children:mb-2 children-last:mr-0"
        :class="{ 'children:flex-1': spaceEvenly }"
    >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'button-list',
        props: {
            spaceEvenly: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

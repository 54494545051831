<template>
    <component
        class="
            inline-flex
            items-center
            justify-center
            py-3
            px-10
            rounded-full
            font-sans
            font-semibold
            shadow-lg
            transition-colors
            text-black
        "
        v-bind="{
            ...componentElement.attrs,
            ...$attrs,
        }"
        :class="[accentClasses]"
        :is="componentElement.tag"
    >
        <span class="mr-2" v-if="hasIconSlot()">
            <slot name="icon"></slot>
        </span>
        <span>
            <slot></slot>
        </span>
    </component>
</template>

<script>
    export default {
        name: 'base-button',
        props: {
            accent: {
                type: String,
                default: 'secondary',
            },
        },
        computed: {
            accentClasses() {
                const accentMap = {
                    white: [
                        'bg-white',
                        'hover:bg-gray-50',
                        'active:bg-gray-100',
                    ],
                    secondary: [
                        'bg-secondary-300',
                        'hover:bg-secondary-200',
                        'active:bg-secondary-100',
                    ],
                };

                return accentMap[this.accent];
            },
            componentElement() {
                if (this.$attrs.href) {
                    return {
                        tag: 'a',
                        attrs: {
                            role: 'button',
                        },
                    };
                }

                if (this.$attrs.to) {
                    return {
                        tag: 'router-link',
                        attrs: {
                            role: 'button',
                        },
                    };
                }

                return {
                    tag: 'button',
                    attrs: {
                        type: 'button',
                        ...this.$attrs,
                    },
                };
            },
        },
        methods: {
            hasIconSlot() {
                return !!this.$slots.icon;
            },
        },
    };
</script>

<template>
    <label class="block text-gray-600 mb-1" v-if="label">
        {{ label }}
        <span class="text-red-500" v-if="required">
            *
        </span>

        <span class="text-xs text-gray-400" v-if="helper">
            ({{ helper }})
        </span>
    </label>
</template>

<script>
    export default {
        name: 'form-label',
        props: {
            label: {
                type: String,
                default: '',
            },
            helper: {
                type: String,
                default: '',
            },
            required: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<template>
    <form-label
        :label="label"
        :helper="labelHelperText"
        :required="hasRequiredValidator"
    ></form-label>
    <component
        :data-invalid="hasValidationError"
        :field="field"
        :is="component"
        v-bind="$attrs"
    >
        <slot></slot>
    </component>
    <form-validation-error
        :errors="sortedValidationErrors"
        v-if="showValidationMessage"
    ></form-validation-error>
</template>

<script>
    import {
        mapKeys,
        camelCase,
    } from 'lodash-es';

    import FormLabel from '@/components/form/utils/FormLabel.vue';
    import FormValidationError from '@/components/form/validation/FormValidationError.vue';
    import { baseInputs } from '@/config/form/base-inputs';
    import { camelCaseKeys } from '@/helpers';

    function mapImportGlobKeys(imports) {
        return camelCaseKeys(mapKeys(imports, (value, key) => {
            const splittedKeys = key.split('/');
            const newKey = splittedKeys[splittedKeys.length - 1].split('.')[0];

            return newKey;
        }));
    }

    const validatorModules = mapImportGlobKeys(import.meta.glob('/src/config/form/validators/*.js', {
        eager: true,
    }));

    export default {
        name: 'form-field',
        components: {
            FormLabel,
            FormValidationError,
        },
        inheritAttrs: false,
        inject: {
            registerField: {
                default() {
                    return () => {};
                },
            },
            deregisterField: {
                default() {
                    return () => {};
                },
            },
            hasSubmitted: {
                default: false,
            },
        },
        props: {
            type: {
                type: String,
                default: 'text',
            },
            modelValue: {
                type: [
                    String,
                    Number,
                    Boolean,
                    Object,
                    Array,
                ],
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            labelHelper: {
                type: String,
                default: '',
            },
            isOptional: {
                type: Boolean,
                default: false,
            },
            validators: {
                type: [String, Array],
                default: '',
            },
            validateOnModelUpdate: {
                type: Boolean,
                default: true,
            },
            validatorParams: {
                type: Object,
                default() {
                    return {};
                },
            },
            validatorMessages: {
                type: Object,
                default() {
                    return {};
                },
            },
            validateImmediately: {
                type: Boolean,
                default: false,
            },
            wrapValueInArray: {
                type: Boolean,
                default: false,
            },
            showValidationMessage: {
                type: Boolean,
                default: true,
            },
        },
        emits: [
            'update:modelValue',
        ],
        data() {
            return {
                validationErrors: [],
                isDirty: false,
                localLabelHelper: '',
                skipWatcher: false,
            };
        },
        computed: {
            field() {
                return {
                    attrs: this.$attrs,
                    modelValue: this.modelValue,
                    updateModelValue: this.updateModelValue,
                    setLabelHelper: this.setLabelHelper,
                    inputClasses: [
                        'block',
                        'w-full',
                        'rounded-md',
                        'px-3 py-2',
                        'placeholder-gray-300',
                        'bg-white',
                        'shadow-sm',
                        'border',
                        'border-gray-100',
                        'font-sans',
                        'font-normal',
                        'text-black',
                        'focus:outline-none',
                        'focus:border-primary-500',
                        'focus:ring-primary-500',
                        'disabled:cursor-not-allowed',
                        'disabled:bg-zinc-50',
                        'disabled:border-zinc-200',
                        'disabled:text-zinc-400',
                        'field-invalid:border-red-500',
                        'field-invalid:text-red-600',
                        'field-invalid:focus:border-red-500',
                        'field-invalid:focus:ring-red-500',
                        this.hasValidationError ? 'field-invalid' : false,
                    ],
                    validity: {
                        isDirty: this.isDirty,
                        validators: this.validatorsAsArray,
                        errors: this.sortedValidationErrors,
                        hasValidationError: this.hasValidationError,
                    },
                };
            },
            component() {
                const camelCasedType = camelCase(this.type);

                if (!baseInputs[camelCasedType]) {
                    // eslint-disable-next-line no-console
                    console.warn(`[form]: input not found, maybe the specified input (${camelCasedType}) is an extended input?`);
                }

                return baseInputs[camelCasedType];
            },
            hasCustomValidationMessages() {
                return !!Object.keys(this.validatorMessages).length;
            },
            labelHelperText() {
                if (this.labelHelper) {
                    return this.labelHelper;
                }

                if (this.isOptional) {
                    return 'optioneel';
                }

                if (this.localLabelHelper) {
                    return this.localLabelHelper;
                }

                return '';
            },
            validatorsAsArray() {
                if (!this.validators) {
                    return [];
                }

                if (Array.isArray(this.validators)) {
                    return this.validators;
                }

                return this.validators.split('|');
            },
            validatorsWithoutParams() {
                return this.validatorsAsArray.map((validator) => {
                    if (typeof validator === 'object') {
                        return validator.name;
                    }

                    return validator.split(':')[0];
                });
            },
            sortedValidationErrors() {
                return [...this.validationErrors].sort((a, b) => {
                    return this.validatorsWithoutParams.indexOf(a.validator)
                        - this.validatorsWithoutParams.indexOf(b.validator);
                });
            },
            hasValidationError() {
                return !!this.validationErrors.length;
            },
            hasRequiredValidator() {
                return this.validatorsAsArray.includes('required');
            },
        },
        watch: {
            modelValue() {
                if (this.skipWatcher) {
                    this.skipWatcher = false;

                    return;
                }

                if (!this.validateOnModelUpdate && !this.hasSubmitted) {
                    return;
                }

                this.setDirtyState();
                this.validateField();
            },
            validators() {
                this.validateField();
            },
        },
        methods: {
            updateModelValue(value) {
                this.$emit('update:modelValue', value);
            },
            setDirtyState(bool = true) {
                this.isDirty = bool;
            },
            setLabelHelper(text) {
                this.localLabelHelper = text;
            },
            async validateField() {
                if (!this.isDirty) {
                    return;
                }

                if (!this.validatorsAsArray.length) {
                    this.validationErrors = [];

                    return;
                }

                await this.validatorsAsArray.forEach(async (validator) => {
                    const [validatorName, params] = this.getValidatorOptions(validator);
                    const args = this.getValidatorParams(params);
                    const validatorResults = await this.getValidity(validatorName, args);

                    const isValid = validatorResults.every((result) => {
                        if (result.valid === false) {
                            return false;
                        }

                        if (result.valid === true) {
                            return true;
                        }

                        return result;
                    });

                    if (isValid) {
                        const index = this.validationErrors.findIndex((error) => {
                            return error.validator === validatorName;
                        });

                        if (index > -1) {
                            this.validationErrors.splice(index, 1);
                        }
                    }

                    if (!isValid) {
                        const hasSameValidator = this.validationErrors.some((error) => {
                            return error.validator === validatorName;
                        });

                        if (!hasSameValidator) {
                            let message = this.$t(`validations.${validatorName}`, {
                                ...validatorResults[0].params,
                                ...this.validatorParams,
                                label: this.label.toLowerCase(),
                            });

                            if (this.hasCustomValidationMessages) {
                                message = this.validatorMessages[validatorName];
                            }

                            this.validationErrors.push({
                                validator: validatorName,
                                message,
                            });
                        }
                    }
                });
            },
            async getValidity(validatorName, args) {
                let value = this.modelValue;

                if (value === null && !validatorName.includes('required')) {
                    return [true];
                }

                if ((value === false && !value.length) && !validatorName.includes('required')) {
                    return [true];
                }

                if (!value && !validatorName.includes('required')) {
                    return [true];
                }

                const resolvedValidator = validatorModules[validatorName];

                if (this.wrapValueInArray) {
                    value = [value];
                }

                if (Array.isArray(value)) {
                    return Promise.all(value.map(async (value) => {
                        const result = await resolvedValidator.default(value, args);

                        return result;
                    }));
                }

                const result = await resolvedValidator.default(value, args);

                return [result];
            },
            getValidatorOptions(validator) {
                if (typeof validator === 'object') {
                    return [validator.name, validator.params];
                }

                return validator.split(':');
            },
            getValidatorParams(params) {
                if (!params) {
                    return undefined;
                }

                if (typeof params === 'object') {
                    return params;
                }

                return params.split(',');
            },
            resetField() {
                this.skipWatcher = true;
                this.validationErrors = [];
                this.setDirtyState(false);
            },
        },
        created() {
            this.registerField(this);

            if (this.validateImmediately) {
                this.setDirtyState();
                this.validateField();
            }
        },
        unmounted() {
            this.deregisterField(this);
        },
    };
</script>

<template>
    <div>
        <hero-gradient class="text-center py-24 xl:py-48" :clip-bottom="true">
            <div class="container mx-auto px-4 lg:px-20 xl:px-72">
                <base-subtitle accent="secondary">
                    Voor werkenden
                </base-subtitle>
                <base-title
                    class="lg:!text-5xl"
                    size="sm"
                    accent="white"
                >
                    Vanaf dag één niet alleen een vast contract,
                    maar eentje met inkomenszekerheid.
                </base-title>
                <base-text accent="white">
                    De missie van coöperatie <em>De</em>Werkgever is om dat te ondersteunen.
                    Gun iedereen een vast contract met inkomenszekerheid.
                    Als collectief van werkenden zorgen we ervoor dat we ons
                    kunnen aanpassen en verzekerd zijn van inkomen,
                    scholing en werk in een onvoorspelbare wereld.
                </base-text>
            </div>
        </hero-gradient>
        <div class="container mx-auto px-4 mb-12 lg:px-20 xl:mb-28">
            <img
                class="relative rounded-xl -top-16"
                src="@/assets/photos/rear-view-of-businesswoman.jpeg"
                alt=""
            >
            <flex-columns>
                <flex-column sm="12" xl="6">
                    <base-subtitle>Jouw situatie</base-subtitle>
                    <base-title size="md" accent="gray">
                        Herken je deze uitdagingen?
                    </base-title>
                    <base-text accent="light">
                        De afgelopen 40 jaar is er veel veranderd op de arbeidsmarkt.
                        Het vaste contract is onzekerder en zijn er veel flex contracten.
                        De zekerheid dat jij kan voorzien in je behoefte is sterk
                        afgenomen door corona en stijgende prijzen. Een zeker inkomen
                        of een vast contract met een goed pensioen is niet vanzelfsprekend.
                        De overheid lijkt ook niet echt mee te werken.
                    </base-text>
                    <base-text accent="light">
                        Jouw werkgever gunt je het beste. Wij lossen dit met elkaar op!
                    </base-text>
                </flex-column>
                <flex-column sm="12" xl="6">
                    <scrollable-list :desktop="true">
                        <image-card
                            title="Werk je van flex naar flex contract?"
                            image="assets/photos/young-frustrated-female-employee.jpeg"
                        >
                            Met DeWerkgever altijd een vast contract mét inkomenszekerheid!
                        </image-card>
                        <image-card
                            title="Ben jij 1 van de 1.6 miljoen zombies?"
                            image="assets/photos/sleeping-hipster-businessman-inside-the-subway.jpeg"
                        >
                            Wij voorkomen inkomensverlies ook als je baan ooit verdwijnt.
                        </image-card>
                        <image-card
                            title="Heb je geen vast contract en kan je geen huis kopen?"
                            image="assets/photos/confused-bearded-male-reading-something.jpeg"
                        >
                            Met een vast contract van ons heb jij de beste papieren.
                        </image-card>
                        <image-card
                            title="Je hebt een 0-urencontract."
                            image="assets/photos/creative-woman-interior-designer.jpeg"
                        >
                            Bij coöperatie DeWerkgever krijg je een vast contract,
                            met inkomenszekerheid tot en met behoud van pensioen.
                        </image-card>
                        <image-card
                            title="Je baas wil niet in je groei investeren."
                            image="assets/photos/portrait-of-young-new-worker.jpeg"
                        >
                            Bij coöperatie DeWerkgever draait het om jou en een leven lang leren.
                        </image-card>
                        <image-card
                            title="Loop je hier ook tegenaan?"
                            image="assets/photos/portrait-of-a-serious-business-lady.jpeg"
                        >
                            <template #action>
                                <base-button :to="{ name: 'contact' }">
                                    Neem contact op
                                </base-button>
                            </template>
                        </image-card>
                    </scrollable-list>
                </flex-column>
            </flex-columns>
        </div>
        <div class="container mx-auto px-4 mb-12 lg:px-20 xl:mb-20">
            <flex-columns class="xl:flex-row-reverse">
                <flex-column sm="12" xl="6">
                    <base-subtitle>Waarom coöperatie DeWerkgever</base-subtitle>
                    <base-title accent="gray" size="md">
                        Ons doel: zorgeloos samenwerken mét inkomenszekerheid.
                    </base-title>
                    <base-text accent="light">
                        Stel je een coöperatie voor die je inkomen verzekerd voor de
                        rest van je loopbaan. Mét een goed pensioen. Ook als je ziek
                        wordt en zelfs als je zelf stopt. Een coöperatie waar jij het
                        voor het zeggen hebt en eigen fondsen opbouwt voor jou en
                        je collega's.
                    </base-text>
                    <base-text accent="light">
                        Coöperatie DeWerkgever is van jou en voor jou en wordt
                        gefinancierd door je werkgever. Inkomenszekerheid is alleen te
                        dragen wanneer we ons verenigen. Wanneer we ervoor zorgen dat
                        jij economisch zelfstandig wordt en blijft. Daarom investeren
                        we in jouw gezondheid en skills, samen met onze partners. Als
                        je gezond bent en aan je skills werkt, blijf je kansrijk. Dit
                        doen we preventief en het kost jou nooit iets extra. Zo
                        voorkomen we dat je inkomensverlies krijgt. En als het toch
                        mis gaat, ben je verzekerd van inkomen tot pensioen, met een
                        goed pensioen.
                    </base-text>
                </flex-column>
                <flex-column sm="12" xl="6">
                    <img
                        class="flex-1"
                        src="@/assets/photos/photo-grid.png"
                        alt=""
                    >
                </flex-column>
            </flex-columns>
        </div>
        <div class="container mx-auto px-4 mb-12 lg:px-20 xl:mb-20">
            <flex-columns>
                <flex-column sm="12" xl="4">
                    <icon-list-item
                        title="Inkomenszekerheid"
                        direction="vertical"
                        :icon="['far', 'circle-arrow-up-right']"
                    >
                        Iedereen een vast contract met inkomenszekerheid.
                        Je bent vanaf dag één verzekerd van inkomen tot pensioen,
                        met behoudt van pensioen. Geen kleine lettertjes. Wij
                        brengen rust, eerlijkheid en zekerheid terug
                        in de arbeidsrelatie.
                    </icon-list-item>
                </flex-column>
                <flex-column sm="12" xl="4">
                    <icon-list-item
                        title="Geen extra kosten"
                        direction="vertical"
                        :icon="['far', 'euro-sign']"
                    >
                        Het kost jou niets. Sterker nog, als eigen risicodrager
                        voor de werknemers-verzekeringen, gebruiken we slechts
                        1/3 voor verzekeringen. De overige 12% gaat naar jouw
                        eigen fondsen. Deze fondsen blijven van jou en gebruiken
                        we samen voor jouw toekomst.
                    </icon-list-item>
                </flex-column>
                <flex-column sm="12" xl="4">
                    <icon-list-item
                        title="Meer regie"
                        direction="vertical"
                        :icon="['far', 'coins']"
                    >
                        Eigen baas over je leven. In samenwerking met professionele
                        partners bepaal jij welke richting je op gaat. En ongeacht
                        de reden van inkomensverlies, wij staan altijd voor je
                        klaar. Wij werken met elkaar aan jouw bestaanszekerheid.
                    </icon-list-item>
                </flex-column>
                <flex-column sm="12" xl="4">
                    <icon-list-item
                        title="Gezondheid"
                        direction="vertical"
                        :icon="['far', 'heart']"
                    >
                        Geen zorgen om inkomen helpt je te focussen op waar het
                        echt om gaat. Door verstandige keuzes te maken in je
                        carrière, proberen we arbeidsongeschiktheid te voorkomen.
                        Ziek zijn is voor niemand leuk. Daarom werken we vanaf
                        dag één aan jouw duurzame inzetbaarheid.
                    </icon-list-item>
                </flex-column>
                <flex-column sm="12" xl="4">
                    <icon-list-item
                        title="Juiste skills"
                        direction="vertical"
                        :icon="['far', 'graduation-cap']"
                    >
                        Scholing is belangrijk om kansrijk te blijven op de
                        arbeidsmarkt. Met een eigen fonds investeren we in een
                        leven lang leren. Je werk is vanzelfsprekend leuker
                        wanneer je er goed in bent. Geen stress én leuk werk houdt
                        je blijer en gezonder in je loopbaan.
                    </icon-list-item>
                </flex-column>
                <flex-column sm="12" xl="4">
                    <icon-list-item
                        title="Impactvol"
                        direction="vertical"
                        :icon="['far', 'earth-europe']"
                    >
                        Stel je voor dat je je nooit meer zorgen hoeft te maken
                        om je inkomen. Je gezondheid op orde is, en je de juiste
                        skills hebt. Pas dan geloven we erin dat jij alle
                        ingrediënten hebt om echt impact te maken op je eigen
                        leven. En op je medemensen.
                    </icon-list-item>
                </flex-column>
            </flex-columns>
        </div>
        <hero-gradient class="text-center py-36" :clip-both="true">
            <div class="container mx-auto px-4 lg:px-20 xl:px-72">
                <base-title accent="white" size="md">
                    Vragen die we vaak krijgen
                </base-title>
                <base-text accent="lightest">
                    Het is op het eerste gezicht moeilijk te geloven dat zo'n
                    initiatief echt mogelijk is. We verzekeren je: dat is het.
                    Maar je zal vast nog wat vragen hebben. We hebben de meest
                    gestelde vragen op een rijtje gezet.
                </base-text>
                <base-accordion active-item="one">
                    <accordion-item name="one" title="Hoe werkt dat, een contractloos bestaan?">
                        Als je lid wordt van de coöperatie, garandeert dat een
                        inkomen en een baan. Je kan makkelijk van baan wisselen
                        binnen de coöperatie, zonder je zekerheden te verliezen.
                        Het lid zijn van de Coöperatie fungeert als een soort
                        contract, maar dan eentje waar jij de regie over hebt.
                    </accordion-item>
                    <accordion-item name="two" title="Draai ik op voor andermans pech?">
                        Nee! Samen met het collectief bouw je een veilige basis in
                        de vorm van een schenkkring, waar iemands pech mee kan
                        worden opgevangen. Je draagt er feitelijk dus wel aan
                        bij, maar voelt er zelf niets van.
                    </accordion-item>
                    <accordion-item name="three" title="Hoe wissel ik binnen de coöperatie van baan?">
                        Je geeft aan dat je graag een andere baan zou willen.
                        Als er een bedrijf binnen het collectief plek voor je
                        heeft, kun je overstappen. De nodige bijscholing wordt
                        vanuit het collectief geregeld.
                    </accordion-item>
                    <accordion-item name="four" title="Wat gebeurt er als ik een hele dure opleiding wil volgen?">
                        Het mooie aan een collectief, is dat beslissingen
                        collectief worden genomen. Als jij goed kunt beargumenteren
                        waarom je zo'n dure opleiding nodig hebt, wordt het vast
                        goedgekeurd. Zolang het een investering is in je
                        vaardigheden, is er van alles mogelijk.
                    </accordion-item>
                    <accordion-item name="five" title="Kost het mij iets?">
                        Het kost je niets. Je werkgever betaald voor jouw plek in
                        het collectief door dezelfde arbeidskosten te betalen,
                        plus 2% uitvoeringskosten.
                    </accordion-item>
                </base-accordion>
            </div>
        </hero-gradient>
        <div class="container mx-auto px-4 lg:px-20">
            <div class="relative rounded-xl -top-16 bg-white">
                <div class="text-center px-8 py-10 xl:px-72 xl:py-24">
                    <base-subtitle>Neem contact met ons op</base-subtitle>
                    <base-title size="md">
                        Nog meer vragen?
                    </base-title>
                    <base-text accent="light">
                        Kun je niet vinden waar je naar zoekt? Maak een praatje
                        met ons op één van onze events of stuur een berichtje.
                        We zijn benieuwd naar jouw vraag of feedback!
                    </base-text>
                </div>
                <div class="px-5 pb-10 xl:px-72">
                    <contact-form></contact-form>
                </div>
            </div>
            <div class="text-center mb-32 xl:px-72">
                <base-title size="md">
                    Ben je nog niet uitgelezen?
                </base-title>
                <base-text accent="light">
                    We werken op dit moment hard aan de overeenkomsten die exact
                    vertellen hoe alles werkt. We hebben een manifest geschreven
                    waarin we ons idee uitleggen. Daar staat onze visie in
                    beschreven en dat delen we graag met jou. Wij zetten ons in
                    voor een gezonde en duurzame arbeidsmarkt. <br>
                    Doe jij mee?
                </base-text>
                <base-button
                    download
                    accent="white"
                    :href="ManifestPdf"
                >
                    <template #icon>
                        <base-icon :icon="['far', 'download']"></base-icon>
                    </template>
                    Download
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
    import HeroGradient from '@/components/gradient/HeroGradient.vue';
    import BaseSubtitle from '@/components/typography/BaseSubtitle.vue';
    import BaseTitle from '@/components/typography/BaseTitle.vue';
    import BaseText from '@/components/typography/BaseText.vue';
    import ScrollableList from '@/components/list/ScrollableList.vue';
    import ImageCard from '@/components/card/ImageCard.vue';
    import IconListItem from '@/components/list/IconListItem.vue';
    import BaseAccordion from '@/components/accordion/BaseAccordion.vue';
    import AccordionItem from '@/components/accordion/AccordionItem.vue';
    import BaseButton from '@/components/button/BaseButton.vue';
    import BaseIcon from '@/components/icon/BaseIcon.vue';
    import FlexColumns from '@/components/columns/FlexColumns.vue';
    import FlexColumn from '@/components/columns/FlexColumn.vue';
    import ContactForm from '@/modules/contact/ContactForm.vue';
    import ManifestPdf from '@/assets/documents/manifest-werken-aan-wendbaarheid.pdf';

    export default {
        name: 'workers-page',
        components: {
            HeroGradient,
            BaseSubtitle,
            BaseTitle,
            BaseText,
            ScrollableList,
            ImageCard,
            IconListItem,
            BaseAccordion,
            AccordionItem,
            BaseButton,
            BaseIcon,
            FlexColumns,
            FlexColumn,
            ContactForm,
        },
        seo() {
            return {
                title: 'Voor werkenden',
                keywords: 'dewerkgever, vast contract, inkomenszekerheid',
                description: 'Vanaf dag één niet alleen een vast contract, maar eentje met inkomenszekerheid.',
            };
        },
        data() {
            return {
                ManifestPdf,
            };
        },
    };
</script>

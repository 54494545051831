import {
    createRouter,
    createWebHistory,
} from 'vue-router';

import HomePage from '@/pages/home/HomePage.vue';
import WorkersPage from '@/pages/workers/WorkersPage.vue';
import EmployersPage from '@/pages/employers/EmployersPage.vue';
import ContactPage from '@/pages/contact/ContactPage.vue';
import ManifestPage from '@/pages/manifest/ManifestPage.vue';
import AboutPage from '@/pages/about/AboutPage.vue';
import ErrorPage from '@/pages/error/ErrorPage.vue';
import {
    readSeoProperties,
    setSeoFields,
} from './helpers';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/:pathMatch(.*)*',
            name: 'error-page',
            component: ErrorPage,
        },
        {
            path: '/',
            name: 'home',
            component: HomePage,
        },
        {
            path: '/troonrede',
            name: 'troonrede',
            component: ManifestPage,
        },
        {
            path: '/werkenden',
            name: 'workers',
            component: WorkersPage,
        },
        {
            path: '/werkgevers',
            name: 'employers',
            component: EmployersPage,
        },
        {
            path: '/over-de-cooperatie',
            name: 'about',
            component: AboutPage,
        },
        {
            path: '/contact',
            name: 'contact',
            component: ContactPage,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    left: 0,
                    top: 0,
                });
            }, 120);
        });
    },
});

router.afterEach((to) => {
    const seoProps = readSeoProperties(to);
    setSeoFields(seoProps);
});

export default router;

import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faComments,
    faBolt,
    faChevronDown,
    faCircleArrowUpRight,
    faEuroSign,
    faCoins,
    faHeart,
    faGraduationCap,
    faEarthEurope,
    faCircleMinus,
    faCirclePlus,
    faDownload,
    faTriangleExclamation,
    faBuilding,
    faBars,
    faXmark,
    faEye,
} from '@fortawesome/pro-regular-svg-icons';

import {
    faPhone,
    faEnvelope,
    faCheckCircle,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faComments,
    faBolt,
    faPhone,
    faEnvelope,
    faChevronDown,
    faCircleArrowUpRight,
    faEuroSign,
    faCoins,
    faHeart,
    faGraduationCap,
    faEarthEurope,
    faCircleMinus,
    faCirclePlus,
    faDownload,
    faTriangleExclamation,
    faBuilding,
    faBars,
    faXmark,
    faCheckCircle,
    faEye,
);

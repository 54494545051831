<template>
    <div class="flex flex-col space-y-8">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'icon-list',
    };
</script>

<template>
    <div>
        <!-- @todo: componentify this -->
        <div
            class="
                absolute
                z-10
                w-full
                flex
                justify-between
                items-stretch
                h-16
                bg-[#246e9b]/80
                xl:px-16
                xl:h-20
            "
        >
            <router-link class="p-5 my-auto" :to="{ name: 'home' }">
                <img
                    class="w-[150px]"
                    src="@/assets/logo/logo-white.svg"
                    alt=""
                >
            </router-link>
            <button
                type="button"
                class="ml-auto w-16 lg:hidden"
                @click="toggleMenu()"
            >
                <base-icon
                    class="text-white"
                    size="lg"
                    :icon="navIcon"
                ></base-icon>
            </button>
            <div
                class="
                    flex
                    flex-col
                    absolute
                    right-0
                    left-0
                    top-[calc(100%+0.5rem)]
                    mx-2
                    p-4
                    rounded-xl
                    shadow-lg
                    lg:static
                    lg:flex-row
                    lg:bg-transparent
                    lg:shadow-none
                    lg:space-x-6
                    lg:items-center
                    bg-primary-700
                "
                :class="[navClasses]"
            >
                <router-link
                    class="
                        py-2
                        text-xl
                        text-white
                        lg:text-base
                        lg:py-0
                        lg:border-b-2
                        lg:border-b-transparent
                    "
                    active-class="
                        px-2
                        rounded-md
                        bg-white/20
                        lg:px-0
                        lg:bg-transparent
                        lg:rounded-none
                        lg:border-b-2
                        lg:!border-b-secondary-300
                    "
                    :to="{ name: 'workers' }"
                >
                    Voor werkenden
                </router-link>
                <router-link
                    class="
                        py-2
                        text-xl
                        text-white
                        lg:text-base
                        lg:py-0
                        lg:border-b-2
                        lg:border-b-transparent
                    "
                    active-class="
                        px-2
                        rounded-md
                        bg-white/20
                        lg:px-0
                        lg:bg-transparent
                        lg:rounded-none
                        lg:border-b-2
                        lg:!border-b-secondary-300
                    "
                    :to="{ name: 'employers' }"
                >
                    Voor werkgevers
                </router-link>
                <router-link
                    class="
                        py-2
                        text-xl
                        text-white
                        lg:text-base
                        lg:py-0
                        lg:border-b-2
                        lg:border-b-transparent
                    "
                    active-class="
                        px-2
                        rounded-md
                        bg-white/20
                        lg:px-0
                        lg:bg-transparent
                        lg:rounded-none
                        lg:border-b-2
                        lg:!border-b-secondary-300
                    "
                    :to="{ name: 'about' }"
                >
                    Over de coöperatie
                </router-link>
                <router-link
                    class="
                        py-2
                        text-xl
                        text-white
                        lg:text-base
                        lg:py-0
                        lg:border-b-2
                        lg:border-b-transparent
                    "
                    active-class="
                        px-2
                        rounded-md
                        bg-white/20
                        lg:px-0
                        lg:bg-transparent
                        lg:rounded-none
                        lg:border-b-2
                        lg:!border-b-secondary-300
                    "
                    :to="{ name: 'contact' }"
                >
                    Contact
                </router-link>
            </div>
        </div>
        <router-view v-slot="{ Component }">
            <fade-transition mode="out-in">
                <component :is="Component"></component>
            </fade-transition>
        </router-view>
    </div>
    <base-footer></base-footer>
</template>

<script>
    import BaseIcon from '@/components/icon/BaseIcon.vue';
    import FadeTransition from '@/components/transitions/FadeTransition.vue';
    import BaseFooter from '@/components/footer/BaseFooter.vue';

    export default {
        name: 'app',
        components: {
            BaseIcon,
            FadeTransition,
            BaseFooter,
        },
        data() {
            return {
                isOpen: false,
            };
        },
        computed: {
            navClasses() {
                if (this.isOpen) {
                    return [];
                }

                return [
                    'hidden',
                    'lg:flex',
                ];
            },
            navIcon() {
                if (this.isOpen) {
                    return ['far', 'xmark'];
                }

                return ['far', 'bars'];
            },
        },
        methods: {
            toggleMenu() {
                this.isOpen = !this.isOpen;
            },
            closeMenu() {
                this.isOpen = false;
            },
        },
        created() {
            this.$router.afterEach(() => {
                this.closeMenu();
            });
        },
    };
</script>

<template>
    <div class="flex-wrap -m-2 md:flex xl:-m-4" :class="[columnsClasses]">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'flex-columns',
        props: {
            sm: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            columnsClasses() {
                if (!this.sm) {
                    return [];
                }

                return [
                    'flex',
                ];
            },
        },
    };
</script>

<template>
    <div
        class="flex flex-col items-center space-y-4"
        v-if="hasSubmittedSuccessfully"
    >
        <base-icon
            class="text-green-700"
            size="2xl"
            :icon="['fas', 'check-circle']"
        ></base-icon>
        <base-text>Contactformulier verstuurd</base-text>
    </div>
    <base-form
        :form="form"
        v-else
        ref="form"
        @submit="onSubmit($event)"
    >
        <flex-columns>
            <flex-column sm="12" xl="6">
                <form-field
                    type="text"
                    label="Voornaam"
                    placeholder="Voornaam"
                    validators="required"
                    v-model="form.firstname"
                ></form-field>
            </flex-column>
            <flex-column sm="12" xl="6">
                <form-field
                    type="text"
                    label="Achternaam"
                    placeholder="Achternaam"
                    validators="required"
                    v-model="form.lastname"
                ></form-field>
            </flex-column>
            <flex-column sm="12">
                <form-field
                    type="text"
                    label="E-mailadres"
                    placeholder="E-mailadres"
                    validators="required|email"
                    v-model="form.email"
                ></form-field>
            </flex-column>
            <flex-column sm="12">
                <form-field
                    type="text"
                    label="Telefoonnummer"
                    placeholder="Telefoonnummer"
                    validators="required"
                    v-model="form.phone"
                ></form-field>
            </flex-column>
            <flex-column sm="12">
                <form-field
                    type="text"
                    label="Bedrijf"
                    placeholder="Bedrijf"
                    v-model="form.company"
                ></form-field>
            </flex-column>
            <flex-column sm="12">
                <form-field
                    type="checkbox"
                    validators="required"
                    v-model="form.privacy"
                >
                    Akkoord met ons privacybeleid.
                </form-field>
            </flex-column>
            <flex-column sm="12">
                <base-button
                    type="submit"
                    class="w-full"
                >
                    Verstuur
                </base-button>
            </flex-column>
        </flex-columns>
    </base-form>
</template>

<script>
    import axios from 'axios';

    import BaseForm from '@/components/form/BaseForm.vue';
    import FormField from '@/components/form/FormField.vue';
    import FlexColumns from '@/components/columns/FlexColumns.vue';
    import FlexColumn from '@/components/columns/FlexColumn.vue';
    import BaseButton from '@/components/button/BaseButton.vue';
    import BaseIcon from '@/components/icon/BaseIcon.vue';
    import BaseText from '@/components/typography/BaseText.vue';
    import { route } from '@/helpers';

    export default {
        name: 'contact-form',
        components: {
            BaseForm,
            FormField,
            FlexColumns,
            FlexColumn,
            BaseButton,
            BaseIcon,
            BaseText,
        },
        props: {
            hasMessageField: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                form: {},
                hasSubmittedSuccessfully: false,
            };
        },
        methods: {
            async onSubmit(form) {
                await this.$recaptchaLoaded();
                const token = await this.$recaptcha('contact');

                axios.post(route('/api/contact'), {
                    ...form,
                    'g-recaptcha-response': token,
                }).then(() => {
                    this.setForm();
                    this.$refs.form.resetFields();
                    this.hasSubmittedSuccessfully = true;
                });
            },
            setForm() {
                this.form = {
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    company: '',
                    privacy: false,
                };
            },
        },
        created() {
            this.setForm();
        },
    };
</script>

export default function required(value) {
    if (Array.isArray(value)) {
        if (value.length > 0) {
            return value.every((item) => {
                return item;
            });
        }

        return !!value.length;
    }

    let parsedValue = value;

    if (typeof value === 'string') {
        parsedValue = value.trim();
    }

    return parsedValue !== ''
        && parsedValue !== null
        && parsedValue !== false;
}

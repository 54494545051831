<template>
    <span
        class="
            block
            lowercase
            font-sans
            font-normal
            tracking-normal
            text-sm
            first-letter:uppercase
            text-red-500 mt-1
        "
        v-if="firstError"
    >
        {{ firstError.message }}
    </span>
</template>

<script>
    export default {
        name: 'form-validation-error',
        props: {
            errors: {
                type: Array,
                default() {
                    return [];
                },
            },
        },
        computed: {
            firstError() {
                return this.errors[0];
            },
        },
    };
</script>

<template>
    <div
        class="hero-gradient"
        :class="[
            clipBottomClasses,
            clipTopClasses,
            clipBothClasses,
        ]"
    >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'hero-gradient',
        props: {
            clipBottom: {
                type: Boolean,
                default: false,
            },
            clipTop: {
                type: Boolean,
                default: false,
            },
            clipBoth: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            clipBottomClasses() {
                if (!this.clipBottom) {
                    return [];
                }

                return [
                    'clip-hero-gradient-bottom',
                ];
            },
            clipTopClasses() {
                if (!this.clipTop) {
                    return [];
                }

                return [
                    'clip-hero-gradient-top',
                ];
            },
            clipBothClasses() {
                if (!this.clipBoth) {
                    return [];
                }

                return [
                    'clip-hero-gradient-both',
                ];
            },
        },
    };
</script>

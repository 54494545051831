<template>
    <component
        class="font-title"
        :class="[
            accentClasses,
            spacingClasses,
            sizeClasses,
        ]"
        :is="tag"
    >
        <slot></slot>
    </component>
</template>

<script>
    export default {
        name: 'base-title',
        props: {
            tag: {
                type: String,
                default: 'p',
            },
            accent: {
                type: String,
                default: 'black',
            },
            spacing: {
                type: String,
                default: 'base',
            },
            size: {
                type: String,
                default: 'base',
            },
        },
        computed: {
            accentClasses() {
                const accentMap = {
                    black: ['text-black'],
                    gray: ['text-gray-600'],
                    white: ['text-white'],
                };

                return accentMap[this.accent];
            },
            spacingClasses() {
                const spacingMap = {
                    none: [],
                    xs: ['mb-3'],
                    sm: ['mb-4'],
                    base: ['mb-5'],
                    lg: ['mb-6'],
                };

                return spacingMap[this.spacing];
            },
            sizeClasses() {
                const sizeMap = {
                    xs: ['text-lg'],
                    sm: ['text-2xl', 'lg:text-[32px]', 'lg:leading-[38px]'],
                    md: ['text-[28px]', 'lg:text-[56px]', 'lg:leading-[68px]'],
                    base: ['text-4xl', 'lg:text-[65px]', 'lg:leading-[78px]'],
                    lg: ['text-5xl'],
                };

                return sizeMap[this.size];
            },
        },
    };
</script>

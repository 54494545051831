<template>
    <div class="flex flex-col">
        <div
            class="
                relative
                mb-2
                rounded-xl
                overflow-hidden
                z-10
                before:absolute
                before:inset-0
                before:bg-gradient-to-b
                before:from-transparent
                before:via-black/20
                before:to-black/70
            "
        >
            <img
                :src="imageUrl"
                class="h-[200px] w-full object-cover object-top lg:h-[566px]"
            >
            <base-title
                class="absolute left-8 bottom-2 xl:left-24 2xl:left-56"
                spacing="none"
                accent="white"
                size="md"
            >
                {{ title }}
            </base-title>
        </div>
        <div
            class="
                relative
                flex
                flex-col
                justify-center
                mx-4
                px-4
                pb-16
                lg:pt-4
                xl:px-12
                xl:mx-12
                2xl:px-28
                2xl:mx-28
            "
            :class="[borderClasses]"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import BaseTitle from '@/components/typography/BaseTitle.vue';

    export default {
        name: 'image-box',
        components: {
            BaseTitle,
        },
        props: {
            title: {
                type: String,
                default: '',
            },
            image: {
                type: String,
                default: '',
            },
            hasBorder: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            imageUrl() {
                return new URL(`/src/${this.image}`, import.meta.url).href;
            },
            borderClasses() {
                if (!this.hasBorder) {
                    return [];
                }

                return [
                    'before:absolute',
                    'before:-top-1',
                    'before:left-0',
                    'before:border-l-2',
                    'before:border-dashed',
                    'before:h-full',
                    'before:border-gray-200',
                    'xl:before:border-l-4',
                ];
            },
        },
    };
</script>

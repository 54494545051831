<template>
    <div class="container mx-auto py-32 px-4 lg:px-40 xl:py-40 xl:px-72">
        <base-title>
            Wat u op Prinsjesdag 2022 eigenlijk had moeten horen.
        </base-title>
        <base-text class="indent-8">
            Vorig jaar spendeerde ik een klein fortuin, uit eigen zak, aan een
            dubbele pagina in de best gelezen Nederlandse dagbladen - met daarin
            een alternatieve troonrede. Dat wat we eigenlijk hadden moeten (of
            willen) horen.
        </base-text>
        <base-text class="indent-8">
            Misschien kan je je hem nog herinneren, en anders kun je
            <a
                class="text-primary-500"
                download
                :href="Document2021"
            >
                hier
            </a>
            je geheugen nog even opfrissen.
        </base-text>
        <base-text class="indent-8">
            Die campagne was een rechtstreekse handreiking naar politiek Den
            Haag, een uitnodiging om constructief met elkaar in gesprek te gaan.
            Simpelweg omdat ik, en velen met mij, denken een oplossing te hebben
            voor de impasse waarin we als maatschappij verkeren. En die dit jaar
            alleen nog maar erger geworden is.
        </base-text>
        <base-text class="indent-8">
            Helaas is ‘men’ daar in Den Haag niet echt op mijn uitnodiging
            ingegaan. Ja, ik werd gevraagd langs te komen op het Ministerie Van
            Sociale Zaken. Voor de vorm. Ze luisterden niet eens naar mijn
            verhaal, ik kreeg in anderhalf uur één bakje slappe koffie en daarna
            stond ik weer buiten met de boodschap: “Laat maar los, wij hebben
            een beter plan, komt goed”. Ik heb - uiteraard - nooit meer wat
            gehoord.
        </base-text>
        <base-text class="indent-8">
            Maar: even terug naar waar het om gaat. Die troonrede van gisteren.
            En wat ik daarvan vind. Of hoe ik hem graag had willen horen. Of:
            hoe ik hem had gebracht als ik het voor het zeggen had.
        </base-text>
        <base-text class="indent-8">
            Net als vorig jaar, ging die van gisteren natuurlijk (weer) nergens
            over. Of beter: had-ie een stuk realistischer kunnen zijn. En
            eerlijker. En slimmer. En oplossingsgerichter.
        </base-text>
        <base-text class="indent-8">
            Net als vorig jaar, had-ie weer niet alleen gericht moeten zijn aan
            ‘De Leden Van De Staten Generaal’. Maar juist (weer) aan: mijn
            buurman. En aan de juf, de flitsbezorger, de slager, de
            pompbediende, de agent, de verpleegster, de boeren en de
            fietsenmaker. Aan alle gepensioneerden. Aan iedereen die
            noodgedwongen aan de zijlijn moet staan. En aan alle andere mensen
            die er in het afgelopen jaar bijgekomen zijn en die - door wat voor
            omstandigheden dan ook - in ons land willen wonen en verblijven.
        </base-text>
        <base-text class="indent-8">
            Maar ook aan alle mensen die zich ook steeds meer zorgen maken over
            hun welvaart, om hun welzijn, om hun welbevinden. En die gisteren
            afgescheept zijn met een zogenaamd koopkrachtpakket van ’wel’ 16
            miljard. Dat is 904 euro en 11 cent per Nederlander per jaar - ik
            heb kennissen die dat alleen al aan gas, water en licht moeten
            betalen tegenwoordig. Per maand, hé?
        </base-text>
        <base-text class="indent-8">
            Goed, terug naar de troonrede - daar waren we gebleven. Dat was een
            lange zit gisteren, niet? Een klein uur lang moeten luisteren naar
            een hoop zalvende en stichtelijke woorden. Bemoedigend soms, zelfs.
            Her en der klonk er zelfs ook wat empathie in door, als ik me niet
            heb vergist.
        </base-text>
        <base-text class="indent-8">
            Niet zo gek, natuurlijk - je zult als Koning in deze tijden maar je
            onderdanen moeten toespreken. Dan kun je het nog in zulke mooie
            woorden laten gieten, je ontkomt er niet aan om het beestje bij de
            naam te noemen: het meest gebruikte woord in 2022 is - nu al - het
            woord ‘crisis’. Spoiler-alert: ook in deze tekst.
        </base-text>
        <base-text class="indent-8">
            En nee, ik zou niet graag in de brogues staan van Willem-Alexander.
            Ook hij lepelt natuurlijk alleen maar op wat er op het Haagsche
            pluche de afgelopen weken allemaal bedacht is om de totale
            ontwrichting van onze maatschappij, onze economie en van ons welzijn
            met een beetje hoop over de bühne te brengen en de mensen in elk
            geval nog het gevoel te geven dat er licht is aan het eind van de
            tunnel. Terwijl-ie vorig jaar van de speechschrijvers nog moest
            beweren dat er van het demissionaire kabinet niet teveel verwacht
            mocht worden, maar dat het volgende kabinet toch echt met grote en
            nieuwe keuzes voor de lange termijn zou komen.
        </base-text>
        <base-text class="indent-8">
            Beetje unheimisch moet zoiets voelen, denk ik. Gaat toch verder dan
            een leugentje om bestwil, en hij heeft al zoveel aan zijn hoofd - ik
            wil niet weten wat hij straks moet betalen aan zijn
            energieleverancier met al die paleizen.
        </base-text>
        <base-text class="indent-8">
            Maar: ‘crisis’ - daar waren we gebleven. Laten we het rijtje nog
            even aflopen. En ze dan ook allemaal écht bij de naam noemen. Zitten
            jullie, of staan jullie? Hou je vast.
        </base-text>
        <base-text>
            Er is een koopkrachtcrisis. <br>
            Er is een asielzoekerscrisis. <br>
            Er is een stikstofcrisis. <br>
            Er is een woningcrisis. <br>
            Er is een voedselcrisis. <br>
            Er is een energiecrisis. <br>
            Er is een pensioencrisis. <br>
            Er is een klimaatcrisis. <br>
            Er is een inflatiecrisis. <br>
        </base-text>
        <base-text class="indent-8">
            En ook het debacle met de ‘toeslagen’ loopt nog steeds. Ondanks dat
            er 26 mensen op dit dossier bij Jeugdzorg schijnen te zitten, zijn
            er van de 1900 uit huis geplaatste kinderen nog steeds 1888 zoek.
            Het werd vorig jaar nog een ‘affaire’ genoemd, maar volgens mij is
            dat ook een crisis.
        </base-text>
        <base-text class="indent-8">
            En ook in de zorg hebben we, ondanks meer dan twee jaar van
            pijnlijke leerpunten, de boel nog totaal niet op orde - ik wil niet
            weten hoe komende winter Covid onze maatschappij en de economie wéér
            finaal gaat ondergraven. En ons nog verder het putje in duwt.
        </base-text>
        <base-text class="indent-8">
            Over al die crises hebben we het afgelopen jaar, en zeker de laatste
            maanden, elke dag weer kunnen lezen: het nieuws lijkt nergens anders
            meer over te gaan.
        </base-text>
        <base-text class="indent-8">
            Boeren die met trekkers de snelweg opgaan. Artsen Zonder Grenzen die
            in eigen land moeten worden ingevlogen om nieuwkomers die niet naar
            binnen kunnen van de grond te rapen, terwijl Rutte vanuit z’n ivoren
            toren zegt dat het “om je voor te schamen is”. Dat klopt, maar wie
            is die ‘je’ dan?
        </base-text>
        <base-text class="indent-8">
            Ouders die de post niet meer open durven te maken en en masse op de
            afgrond van de schuldsanering afrennen - of ze nu willen of niet.
            Die krijgen straks te maken met de schuldenindustrie, en geloof me:
            daar kom je nooit meer van af. Haaien zijn het, met de exorbitante
            boetes en rentepercentages die ze buiten het systeem om opleggen (en
            het zijn echt niet meer alleen die armsten die het haasje zijn).
        </base-text>
        <base-text class="indent-8">
            Starters op de woningmarkt die maar niet starten, niet van hun plek
            kunnen komen en tot ver na hun 30ste bij papa en mama thuis moeten
            wonen. Vaak met een mooi papiertje op zak dankzij de
            studiefinanciering. Overigens: één van meest verkeerd gebruikte
            woorden in de Nederlandse taal want het is natuurlijk gewoon een
            keiharde studieschuld.
        </base-text>
        <base-text class="indent-8">
            Tachtigduizend vacatures in de zorg die niet in te vullen zijn,
            huisartsen die zich ziek moeten melden door een onmenselijke
            werkdruk en wachtlijsten in de GGZ die je met een goed Nederlands
            woord alleen maar totaal gestoord kunt noemen - over 3 jaar
            bezwijken we collectief aan al onze zorgen die steeds groter worden
            en waar we straks met niemand meer professioneel over kunnen praten.
        </base-text>
        <base-text class="indent-8">
            Er wordt gezegd dat het economisch zo goed gaat met Nederland. Want
            de hoge inflatie en gasprijzen komen de staat wel goed uit. Maar om
            eerlijk te zijn: ik merk er niks van. En u volgens mij u ook niet.
            Het kan zijn dat de ambtenaren en ministers dat allemaal niet zo
            voelen, maar dat heb je met elke maand een zeker inkomen met
            bijbehorend luxe loonstrookje.
        </base-text>
        <base-text class="indent-8">
            Ze leven in een andere werkelijkheid, waarin de waarheid slechts één
            kabinetsperiode actueel is, maar die feitelijk een veel duurzamere,
            een veel ingrijpendere en veel meer decennia-overstijgende manier
            van denken nodig heeft om er ooit nog uit te komen. Die waarheid is
            in werkelijkheid namelijk harder dan hard.
        </base-text>
        <base-text class="indent-8">
            Ons land is failliet. Economisch. Sociaal. Mentaal.
        </base-text>
        <base-text class="indent-8">
            En: moreel. Want ook het huidige kabinet gaat de werkelijke
            problemen waarvan deze crises slechts symptomen zijn, echt niet bij
            de wortel aanpakken.
        </base-text>
        <base-text class="indent-8">
            Toegegeven. Het minimumloon gaat omhoog (en daarmee de uitkeringen
            ook). Maar: dat is slechts 10% en geldt pas vanaf januari. Tegen de
            tijd dat we januari ongeschonden bereikt hebben, is de inflatie al
            verdubbeld en die 10% dus alweer verwaarloosbaar.
        </base-text>
        <base-text class="indent-8">
            Maar die verlaging van de eerste schijf van de inkomstenbelasting
            dan? Juist, daar zit hem nu juist de crux. Dat gaat over de eerste
            schijf, dus over de laagste inkomens. Daar is heus wel iets te
            halen, maar: procentueel gezien schiet het natuurlijk vele malen
            meer op als je juist meer belasting heft op gebruik, bezit en
            vermogen. Je moet het geld immers daar halen, waar het zit.
        </base-text>
        <base-text class="indent-8">
            En nee: tariefverhoging in Box 3, die schiet niet zoveel op. Dat
            levert de schatkist volgend jaar slechts een symbolische 100 miljoen
            euro op (en de komende jaren nog eens 200 miljoen) - een gotspe
            natuurlijk als je je bedenkt dat in Nederland ongeveer 10% van de
            mensen zo’n 70% (!) van het totale vermogen onderling mag verdelen.
            Ja, lees het nog maar eens goed: één-tiende van de Nederlanders
            heeft meer dan twee-derde van het totale bezit in handen.
        </base-text>
        <base-text class="indent-8">
            De overdrachtsbelasting die omhoog gaat? De vennootschapsbelasting
            die 4% stijgt? Allemaal pleisters. Had natuurlijk al lang een keer
            moeten gebeuren. Net als die ‘mijnbouwheffing’ (had u ooit van dat
            woord gehoord?). Die opeens omhoog gaat zodat energiebedrijven meer
            belasting moeten betalen op de winning van olie en gas. Ik begrijp
            nu wel dat Shell en ExxonMobil de NAM willen verkopen. Twee miljard
            euro regelen we daarmee voor ons noodpakket, terwijl juist in de
            afgelopen jaren die energiebedrijven als een dolle hebben lopen
            graaien. Gemiste kans, en ja: fijn. Dat we er nu in elk geval iets
            aan gaan doen.
        </base-text>
        <base-text class="indent-8">
            En nu we het daar toch over hebben, over die energiebedrijven, en
            dat onzinnige voorstel om hun overwinsten terug te geven aan de
            burger? Ik had tot nu toe ook van het woord ‘overwinsten’ nog nooit
            gehoord, om eerlijk te zijn. Je maakt winst, en dat is logisch. En
            fair. Als onderneming, en als maatschappij, draai je daar op. Daar
            ben je helder over, dat is al normaal sinds grote economen als
            Keynes het Marxisme onderuit schopten. Dat staat ook in je boeken,
            in je cijfers, in je administratie. Daar ben je open over, want als
            ondernemer heb je winst nodig om te investeren in - ik noem maar wat
            - innovatie. Bijvoorbeeld.
        </base-text>
        <base-text class="indent-8">
            Maar: ‘overwinsten’? Wie bepaalt wat overwinsten zijn, eigenlijk?
            Tot 2 jaar geleden was Eneco - om maar iets te noemen- nog gewoon
            eigendom van heel veel Nederlandse gemeenten, en had deze discussie
            nooit gespeeld. Lang leve de privatisering, waar voornamelijk
            oud-ambtenaren nog even hun zakken kunnen vullen in de veilige
            private markt.
        </base-text>
        <base-text class="indent-8">
            Vergeet ik er nog één? Vast. Sterker nog, ik zal er nog wel een hele
            hoop vergeten.
        </base-text>
        <base-text class="indent-8">
            Maar de versnelde afbouw van de zelfstandigenaftrek, dat is er nog
            wel eentje om te benoemen. Mensen die zich willen onttrekken aan de
            huidige ratrace van hun loonslavenbestaan, en lekker willen gaan
            ZZP’en, autonoom, zelfstandig en onder eigen regie (gelijk hebben
            ze) weer financieel ontmoedigd worden. Terwijl dat, juist dat, niet
            de reden was dat ze zijn gaan ZZP’en. Sterker nog: zij waren juist
            bezig een oplossing te vinden daar waar die ook gezocht moet worden:
            in de manier waarop we over arbeid, over werk denken.
        </base-text>
        <base-text class="indent-8">
            Want over de grootste crisis die ons de komende jaren te wachten
            staat, wordt nog steeds met geen woord gerept: de crisis op de
            arbeidsmarkt.
        </base-text>
        <base-text class="indent-8">
            Nee niet de arbeidsmarktkrapte. Dat is geen crisis, dat is gezond.
            Daarmee komen we eindelijk af van ‘zombie- en bullshitbanen’, zodat
            een baan ook echt iets waardevols betekent. Voor de mensen die ze
            willen invullen. En: voor de samenleving.
        </base-text>
        <base-text class="indent-8">
            Ok wat voor crisis dan? Crisis op de arbeidsmarkt? Dat was nu juist
            toch net nog het enige dat wél goed gaat in Nederland?
        </base-text>
        <base-text class="indent-8">
            Nou, eigenlijk niet. De crisis op de arbeidsmarkt is misschien wel
            de meest verborgen crisis van het moment. Want na 120 jaar op
            dezelfde manier over werk gedacht te hebben, zullen we er nu echt
            eens op een andere manier naar moeten gaan kijken. En met andere
            oplossingen gaan komen. En snel ook. Doen we dat niet, dan loopt de
            boel keihard vast.
        </base-text>
        <base-text class="indent-8">
            Honderdtwintig jaar? Ja, honderdtwintig jaar. Zo lang baseren we
            onze arbeidswetgeving al op een wetje dat ook destijds al in het
            leven werd geroepen om werkloosheid ‘even snel’ tegen te gaan. Ook
            in 2022, heeft men in Den Haag nog steeds maar één doel: ‘een
            optimale economische ontwikkeling met een onzelfstandige
            beroepsbevolking’, net als 120 jaar geleden. En dat terwijl de
            tijden toch echt veranderd zijn - om nog maar te zwijgen van de
            mensen.
        </base-text>
        <base-text class="indent-8">
            Zeg nou zelf: ben jij nog bezig met ‘life time employment’? Hoeveel
            mensen heb jij nog in je omgeving die 25 jaar voor één en dezelfde
            baas werken?
        </base-text>
        <base-text class="indent-8">
            Onze ouders deden dat misschien, maar die hadden een ander belang,
            die leefden in een andere tijd. Er moest een land dat in puin lag na
            de oorlog weer worden opgebouwd. Onze ouders werkten in eerste
            instantie voor het geld, wij konden het doen om het werk zelf (en
            daarna pas voor het geld) en de nieuwe generaties zijn nog veel
            minder bezig met geld - die willen vooral ook iets bijdragen aan een
            betere wereld.
        </base-text>
        <base-text class="indent-8">
            Waar je tot 10 jaar geleden als student nog dolgraag stage wilde
            lopen bij een grote corporate om van daaruit uit ontdekt te worden
            als ‘High Potential’ en tot aan je graf door alle managementlagen
            begeleidt te worden, begint de gemiddelde student nu liever een
            start-up. Al tijdens de studie, hé?
        </base-text>
        <base-text class="indent-8">
            Niemand is meer bezig met een baan voor het leven. Zelfs een
            balletdanser wordt op zijn of haar 24e al voorbereid op een toekomst
            zónder podium en wat voor balletdansers geldt, geldt voor elk fysiek
            of geestelijk zwaar beroep. Hebben we niet allemaal het recht om op
            een gezonde manier te genieten van ons pensioen..
        </base-text>
        <base-text class="indent-8">
            En werk vormt nu eenmaal een heel groot deel van ons bestaan, van
            ons ‘zijn’ van onze identiteit. Werk -vooral leuk werk- zorgt ervoor
            dat je groeit, dat je ontwikkelt, dat je ergens onderdeel van bent.
            Werk zorgt voor sociale interactie, voor zelfrespect, voor
            persoonlijke ontwikkeling. En uiteraard zorgt het voor brood op de
            plank, liefst een beetje leuk belegd. Het zorgt ervoor dat je leuke
            dingen kunt doen, kunt leren, kunt genieten - het liefst ook met een
            beetje zicht op een rustige, welverdiende oude dag, zonder de stress
            of je dan nog wel rond kunt komen en een betaalbaar dak boven je
            hoofd hebt.
        </base-text>
        <base-text class="indent-8">
            Maar de werkelijkheid is anders. Tallozen onder ons gaan van dag
            naar dag, durven niet eens aan later te denken, en zijn al blij als
            ze het einde van de maand weer halen zonder rood te staan. Er is
            stress, er is angst, er heerst onzekerheid.
        </base-text>
        <base-text class="indent-8">
            Het aantal burn-outs zegt genoeg: we kruipen al naar de 20% van alle
            werknemers in Nederland. Dat is één vijfde!
        </base-text>
        <base-text class="indent-8">
            Daarbij, nog eens één (ander) vijfde haalt hun pensioen niet eens,
            omdat ze niet tijdig zijn omgeschoold en om medische redenen
            uitvallen. We kunnen alleen maar raden wat dat betekent voor de
            kosten van zorg. De werkelijke kosten van deze zieke situatie lopen
            in de miljarden, schat ik. Neem alleen al de bedragen die er naar
            juristen en advocaten gaan, als er bijvoorbeeld gesteggeld moet gaan
            worden over weggaan of blijven - in die zin is het Nederlands
            arbeidsrecht natuurlijk ook pervers georganiseerd.
        </base-text>
        <base-text class="indent-8">
            Denk even mee: wie kent er niet iemand die maar blijft hangen in een
            baan die niet meer bij hem of haar past en die tot ongezonde
            situaties leidt, zowel voor werkgever als werknemer? Hoeveel mensen
            zitten er niet vast in een uitzichtloze situatie, simpelweg omdat ze
            geen ontslag kúnnen nemen? Want ontslag nemen betekent: geen
            uitkering. Geen eten, geen dak, geen kleding voor de kinderen en een
            pensioengat: geen appeltje voor de dorst als aanvulling op je
            aow-tje, straks.
        </base-text>
        <base-text class="indent-8">
            Er zitten miljoenen mensen klemvast, in een vreselijke tang. Een
            catch-22 waaruit het onmogelijk is te ontsnappen. Of om simpelweg
            een gezonde en vrije keuze te kunnen maken. Met z’n allen houden we
            elkaar in een volstrekt onnodige wurggreep, die maatschappelijk
            verstrekkende gevolgen heeft. Immers: onze behoeften, dromen, wensen
            en ambities zijn sterk veranderd, en de manier waarop onze levens
            lopen (of we ze zelf vormgeven) ook. Maar de overheid behandelt ons
            nog steeds als een stel arbeiders aan een lopende band die van
            loonzakje naar loonzakje werken.
        </base-text>
        <base-text class="indent-8">
            En nee: ook die overheid doet het ongetwijfeld niet expres,
            allemaal. Aan de intenties ligt het niet, daar ben ik van overtuigd.
            Ze heeft een ondankbare taak ook, laten we dat vooral niet vergeten.
            Ga er maar aan staan - als ongetwijfeld bevlogen bewindsman of
            -vrouw - om je in die Haagse slangenkuil te storten, en langzaam
            maar zeker je idealen ingeruild zien te worden voor pragmatisme,
            realisme, onderhandelingstactieken, wisselgeld, vage belangen van
            nog vagere stakeholders en de druk van verkiezingsvoorspellingen.
        </base-text>
        <base-text class="indent-8">
            Zij liever dan ik.
        </base-text>
        <base-text class="indent-8">
            Maar toch: de afgelopen drie decennia hebben we als maatschappij
            niet stil gestaan, maar heeft ‘de’ overheid wél zitten slapen. Ze
            nam de democratie en de rechtsstaat voor lief (en wij ook) en onze
            alertheid op misstanden nam af - of we ‘frame-den’ ze naar
            ‘incidenten’ of ‘affaires’.
        </base-text>
        <base-text class="indent-8">
            We leefden in de waan van de dag in relatieve onbezorgdheid en
            lieten de arbeidsmarkt en ons stelsel van sociale zekerheid
            versloffen. En verstoffen.
        </base-text>
        <base-text class="indent-8">
            Maar: ons kapitalistische systeem is kapot. Het werkte misschien
            toen het grootste deel van de samenleving er beter van werd - maar
            inmiddels worden de boeren, de middenstanders en steeds meer andere
            mensen ook geraakt door het graaien van de rijken.
        </base-text>
        <base-text class="indent-8">
            In de miljoenennota van gisteren wordt arbeid zoals altijd wéér het
            zwaarste belast, in plaats van datgene dat veel logischer zou zijn:
            Vermogen. Gebruik. En bezit. Je zou bijna kunnen zeggen: de overheid
            maakt het steeds onaantrekkelijker om überhaupt nog te (willen)
            werken.
        </base-text>
        <base-text class="indent-8">
            In zo’n wereld wil ik niet leven, jullie wel?
        </base-text>
        <base-text class="indent-8">
            We kunnen dat alleen oplossen als we rigoureus anders gaan denken
            over werk. En de arbeidsmarkt niet meer als een markt zien - dat
            zorgt er namelijk voor dat er inmiddels meer verdiend wordt áán
            arbeid dan met arbeid.
        </base-text>
        <base-text class="indent-8">
            Het is belangrijk dat we gaan denken vanuit het belang van de
            werknemer, eerder dan vanuit het belang van de werkgever - een
            werkgever wil winst maken, een werknemer wil groeien. Tussen beiden
            moet een gezond evenwicht mogelijk zijn.
        </base-text>
        <base-text class="indent-8">
            Daarvoor moeten we arbeid in een groter filosofisch, historisch,
            sociaal en economisch perspectief zetten waarbij we niet meer
            streven naar baanzekerheid, maar naar inkomenszekerheid.
        </base-text>
        <base-text class="indent-8">
            Daar staat het: in één zin. In één regel. En ja, zo simpel is het
            echt.
        </base-text>
        <base-text class="indent-8">
            Ga maar na: als iedereen zijn of haar hele leven verzekerd zou
            zijn van inkomen, van een baan die écht bij hem of haar past, zonder
            vast te zitten aan complexe contracten en ingewikkelde regelgeving
            uit het jaar nul (om precies te zijn het jaar 1907), dan gaat de
            wereld er een stuk mooier op worden. Dan staan werkgevers en
            werknemers niet meer tegenover elkaar, maar halen ze samen het beste
            uít elkaar. Dan gaan we werken vanuit respect en vertrouwen, naar en
            voor elkaar. Dan is iedereen continue, zijn of haar hele loopbaan
            lang, alleen maar bezig de beste (werkende) versie van zichzelf te
            worden en zijn of haar talenten daar in te zetten waar werkgevers
            die het beste kunnen gebruiken.
        </base-text>
        <base-text class="indent-8">
            Utopisch? Te mooi om waar te zijn? Nee hoor, verre van. In veel
            gevallen gebeurt dit zelfs eigenlijk al, maar we zien het niet. Of
            we zien het grotere plaatje niet. Kan niet, bestaat niet’, heb ik
            inmiddels geleerd in al die jaren dat ik hier al van droom en elke
            dag mijn bed weer voor uitkom. En nee, ik ben geen politicus. Verre
            van. Sterker nog: ik ben ondernemer. Dus: ik heb echt niet overal
            alle antwoorden op. Maar toevallig weet ik wél wat van de
            arbeidsmarkt. En denk ik dat juist daar een groot deel van de (hele)
            oplossing zit.
        </base-text>
        <base-text class="indent-8">
            De oplossing zit ‘m alleen niet in mij. Niet in mij alleen. Niet in
            één iemand, in één bedrijf dat het wel even fixed. De oplossing zit
            sowieso in samenwerking, in het collectief in plaats van het
            individu. Dat was vroeger zo, dat is nu nog zo en dat zal altijd zo
            blijven: in je eentje ben je tenslotte nergens.
        </base-text>
        <base-text class="indent-8">
            In die zin was het een machtig mooie ervaring dat er vanuit
            ondernemend Nederland tenminste wél gereageerd werd op mijn
            alternatieve troonrede van vorig jaar.  Door talloze mensen die niet
            langer willen (af)wachten, maar gewoon: dóórpakken.
        </base-text>
        <base-text class="indent-8">
            Ook zij delen de visie dat het omslagpunt is bereikt, en dat de
            arbeidsmarkt toe is aan een revolutie. Ze onderschrijven dat er
            nieuwe keuzes gemaakt moeten worden, dat het huidige monopolie van
            versoberde en selectieve sociale zekerheid vervangen moet worden
            door een systeem dat een rechtvaardig perspectief op
            bestaanszekerheid biedt. In plaats van een systeem van 120 jaar oud
            dat ons gevangen houdt.
        </base-text>
        <base-text class="indent-8">
            Wie nu al meedoen? Het zijn er teveel om allemaal op te sommen, maar
            een aantal wil ik wel bij naam genoemd hebben.
        </base-text>
        <base-text class="indent-8">
            Neem nou
            <a
                href="https://www.rooswouters.nl/"
                target="_blank"
                class="text-primary-500"
            >
                Roos Wouters
            </a>
            van de
            <a
                href="https://www.werkvereniging.nl/"
                target="_blank"
                class="text-primary-500"
            >
                Werkvereniging
            </a>
            of Paulien Osse van de
            <a
                href="https://wageindicator.org/"
                target="_blank"
                class="text-primary-500"
            >
                WageIndicator
            </a>
            , die al jaren vergeefs de hoofden van beleidsmakers uit de polder
            proberen te trekken. Maar die vooral - samen met mij geloven -
            in fatsoenlijke, humane en betaalbare oplossingen.
        </base-text>
        <base-text class="indent-8">
            Maar ook opleiders als
            <a
                href="https://www.saltagroup.com/"
                target="_blank"
                class="text-primary-500"
            >
                Salta Groep
            </a>
            ,
            <a
                href="https://academiewerkendleren.nl/"
                target="_blank"
                class="text-primary-500"
            >
                Academie Werkend Leren
            </a>
            en
            <a
                href="https://www.goodhabitz.com/"
                target="_blank"
                class="text-primary-500"
            >
                GoodHabitz
            </a>
            klopten aan de deur en sloten aan.
        </base-text>
        <base-text class="indent-8">
            Of verzekeraars als
            <a
                href="https://www.aiginsurance.nl/"
                target="_blank"
                class="text-primary-500"
            >
                AIG
            </a>
            en
            <a
                href="https://www.abnamro.nl/"
                target="_blank"
                class="text-primary-500"
            >
                ABN Amro
            </a>
            - ik heb ze graag achter me staan.
        </base-text>
        <base-text class="indent-8">
            Nog meer? Zeker!
        </base-text>
        <base-text class="indent-8">
            De
            <a
                href="https://fastforwardgroup.net/"
                target="_blank"
                class="text-primary-500"
            >
                Fast Forward Groep
            </a>
            en
            <a
                href="https://www.goed.co/"
                target="_blank"
                class="text-primary-500"
            >
                Arbodienstverlener GOED
            </a>
            , bijvoorbeeld die -
            heel innovatief - met de data van de
            <a
                href="https://intelligence-group.nl/"
                target="_blank"
                class="text-primary-500"
            >
                Intelligence Group
            </a>
            de juiste beslissingen helpen nemen op de juiste momenten. Ze zien
            allemaal dat een duurzaam model op de arbeidsmarkt blijft liggen. En
            ze hebben me geholpen om een systeem te ontwikkelen waarin de
            uitgangspunten van het vaste contract weer de norm kan worden, maar
            dan anders. Een vast contract die er voor iedereen is, die van
            resources kapitaal maakt.
        </base-text>
        <base-text class="indent-8">
            Want ook een huis vervangen we niet wanneer de voordeur piept,
            waarom doen we dat met mensen dan wel? Waarom denken we dat
            werkgevers gemotiveerd zijn om mensen in vaste dienst te nemen als
            het risico op doorbetaling bij ziekte twee tot drie jaar is? En
            waarom investeren we niet preventief in kansrijke banen, met een
            duidelijke blik op de toekomst waarin we nu eenmaal niet aan
            robotisering en verregaande automatisering ontkomen?
        </base-text>
        <base-text class="indent-8">
            Samen met iedereen die zich inmiddels aangesloten heeft (en iedereen
            die zich na deze publicatie ongetwijfeld ook nog gaat aansluiten),
            gaat De Werkgever organisaties helpen om alle werkenden een vast
            contract met inkomenszekerheid te geven.
        </base-text>
        <base-text class="indent-8">
            Dat doen we met een nieuwe coöperatie die het huidige systeem
            eindelijk een halt toeroept, en er een beter alternatief naast zet.
            Beter voor werkgevers, beter voor werknemers, en beter voor de
            samenleving. Beter voor u, beter voor mij. En uiteindelijk beter
            voor een eerlijke verdeling van welvaart. En van: geluk.
        </base-text>
        <base-text class="indent-8">
            Door deel te nemen aan deze nieuwe coöperatie, als organisatie of
            als werkende, gunnen we elkaar inkomenszekerheid zonder de
            contractvorm leidend te laten zijn.
        </base-text>
        <base-text class="indent-8">
            En omdat we zelf risicodragend zijn voor de werknemersverzekeringen
            kunnen we met eigen fondsen in onszelf blijven investeren. In onze
            ontwikkeling, in onze persoonlijke groei, in onze kansen op de
            arbeidsmarkt, in ons werkgeluk en daarmee in een gelukkigere en
            evenwichtigere samenleving.
        </base-text>
        <base-text class="indent-8">
            Als coöperatie helpen we organisaties goed en gezond werkgeverschap
            te faciliteren en werknemers te creëren die zich makkelijker kunnen
            aanpassen aan veranderende omstandigheden in een dikwijls
            onvoorspelbare wereld. Dat kan met gemak tot aan je pensioen, en ja:
            ook als je een zogeheten ‘afstand tot de arbeidsmarkt hebt’ - wat ik
            overigens altijd al een vreselijke uitdrukking heb gevonden. En kun
            je niet werken door ziekte? Ook dan ben je goed verzekerd tot je
            pensioen.
        </base-text>
        <base-text class="indent-8">
            Samen gaan we de verantwoordelijkheid nemen met en voor werkenden én
            organisaties. Samen gaan we organisaties faciliteren om wendbaar te
            blijven in een veranderende wereld. Door te durven investeren in
            innovatie en groei te gunste van ons allemaal, maar vooral:
            preventief investeren in werkenden zodat ze een loopbaan ook
            volhouden. Met behoud van hun autonomie, en van regie.
        </base-text>
        <base-text class="indent-8">
            Dat doen we door als coöperatie met elkaar het risico te dragen bij
            ziekte en werkloosheid, en ja: die rekensommen zijn allemaal al
            gemaakt. Het kost niet meer  dan hoe we het nu doen, maar het levert
            ons allemaal veel meer op.
        </base-text>
        <base-text class="indent-8">
            Ik heb mezelf het afgelopen jaar - net zoals het jaar daarvoor -
            wéér de vraag gesteld: “In wat voor een land wil ik wonen, werken en
            leven?”
        </base-text>
        <base-text class="indent-8">
            Het antwoord daarop is niet veranderd: in een land waarin iedereen
            gelijke kansen heeft en zelf de regie heeft over zijn of haar
            loopbaan en inkomsten.
        </base-text>
        <base-text class="indent-8">
            In een land waar welvaart eerlijker verdeeld wordt.
        </base-text>
        <base-text class="indent-8">
            In een land waar niet de waan van de dag de dienst uitmaakt, maar
            een realistische en positieve blik op de toekomst.
        </base-text>
        <base-text class="indent-8">
            Daar zijn we nog (steeds) niet. Maar: niks doen is geen optie meer.
            En daarom gaan we in het eerste kwartaal van 2023 beginnen. Linksom.
            Of rechtsom.
        </base-text>
        <base-text class="indent-8">
            Wil je meedoen, je aansluiten, een bijdrage leveren, meer weten?
        </base-text>
        <base-text class="indent-8">
            Bel me. Of mail me.
        </base-text>
        <base-text class="indent-8">
            Edward Belgraver.
        </base-text>
        <base-text :has-spacing="false">
            <a href="tel:0614443331" class="text-primary-500">
                0614443331
            </a>
        </base-text>
        <base-text class="indent-8">
            <a href="mailto:belgraver@verloning.nl" class="text-primary-500">
                belgraver@verloning.nl
            </a>
        </base-text>
    </div>
</template>

<script>
    import BaseTitle from '@/components/typography/BaseTitle.vue';
    import BaseText from '@/components/typography/BaseText.vue';
    import Document2021 from '@/assets/documents/troonrede-2021.pdf';

    export default {
        name: 'manifest-page',
        components: {
            BaseTitle,
            BaseText,
        },
        seo() {
            return {
                title: 'Wat u op Prinsjesdag 2022 eigenlijk had moeten horen.',
                keywords: 'werkgever, inkomenszekerheid, prinsjesdag, troonrede',
                description: 'De troonrede van 2022',
            };
        },
        data() {
            return {
                Document2021,
            };
        },
    };
</script>

import { createApp } from 'vue';
import VueScrollTo from 'vue-scrollto';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueGtag from 'vue-gtag';

import router from '@/router';
import i18n from '@/i18n';
import App from './App.vue';
import './css/index.css';

import '@/config/font-awesome';

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(VueScrollTo);
app.use(VueReCaptcha, {
    siteKey: '6LdPQvshAAAAAKfUO0d47Qt4oiNVaJf3upY37y7s',
});

if (import.meta.env.PROD) {
    app.use(VueGtag, {
        config: {
            id: 'G-9Y8YN6LQ24',
        },
    }, router);
}

app.config.unwrapInjectedRef = true;

router.isReady().then(() => {
    app.mount('#app');
});

export function readSeoProperties(route) {
    const matchedChildRoute = route.matched.find((match) => {
        return match.name === route.name;
    });

    if (matchedChildRoute.components.default.seo) {
        return matchedChildRoute.components.default.seo();
    }

    if (route.matched[0].components.default.seo) {
        return route.matched[0].components.default.seo();
    }

    // set seo defaults when route hasn't got any specific seo properties
    return {
        title: 'DeWerkgever',
        suffix: '',
    };
}

export function setSeoFields(props) {
    const mergedProps = {
        suffix: '| DeWerkgever',
        keywords: '',
        description: '',
        ...props,
    };

    document.title = `${mergedProps.title} ${mergedProps.suffix}`;
    document.querySelector('meta[name="keywords"]')
        .setAttribute('content', mergedProps.keywords);
    document.querySelector('meta[name="description"]')
        .setAttribute('content', mergedProps.description);
}

<template>
    <div class="p-1">
        <div
            class="flex justify-center items-center aspect-square max-w-[70px] p-1 rounded-xl lg:max-w-[100px]"
            :class="[emptyClasses]"
        >
            <img :src="imageUrl" v-if="!isEmpty">
            <template v-else>
                <span class="font-title text-[42px] text-secondary-300 xl:text-[68px]">
                    ?
                </span>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'logo-cloud-item',
        props: {
            image: {
                type: String,
                default: '',
            },
            isEmpty: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            imageUrl() {
                return new URL(`/src/${this.image}`, import.meta.url).href;
            },
            emptyClasses() {
                if (!this.isEmpty) {
                    return [
                        'bg-white/10',
                    ];
                }

                return [
                    'bg-primary-600',
                ];
            },
        },
    };
</script>

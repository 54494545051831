<template>
    <div
        class="
            grid
            grid-flow-col
            auto-cols-[90%]
            gap-4
            overflow-auto
            pb-8
        "
        :class="[breakpointClasses]"
    >
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'scrollable-list',
        props: {
            desktop: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            breakpointClasses() {
                if (this.desktop) {
                    return [];
                }

                return [
                    'xl:flex',
                    'xl:overflow-visible',
                ];
            },
        },
    };
</script>

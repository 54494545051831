<template>
    <transition
        name="expand"
        @enter="enter"
        @after-enter="afterEnter"
        @leave="leave"
        @after-leave="afterLeave"
    >
        <slot></slot>
    </transition>
</template>

<script>
    export default {
        name: 'expand-transition',
        methods: {
            afterEnter(element) {
                element.style.height = null;
            },
            enter(element) {
                const { width } = getComputedStyle(element);

                // So computed values are set immediately so we can read them.
                element.style.transition = `none`;
                element.style.width = width;
                element.style.position = `absolute`;
                element.style.visibility = `hidden`;
                element.classList.remove(`expand-enter-from`);

                const { height } = getComputedStyle(element);

                element.classList.add(`expand-enter-from`);
                element.style.width = null;
                element.style.position = null;
                element.style.visibility = null;

                // Force repaint to make sure the
                // animation is triggered correctly.
                // eslint-disable-next-line no-unused-expressions
                getComputedStyle(element).height;

                // Done changing properties, turning transitions back on.
                element.style.transition = null;

                requestAnimationFrame(() => {
                    element.style.height = height;
                });
            },
            leave(element) {
                const { height } = getComputedStyle(element);

                element.style.height = height;
                // Force repaint to make sure the
                // animation is triggered correctly.
                // eslint-disable-next-line no-unused-expressions
                getComputedStyle(element).height;

                requestAnimationFrame(() => {
                    element.style.height = 0;
                });
            },
            afterLeave(element) {
                element.style.height = null;
            },
        },
    };
</script>

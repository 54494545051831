<template>
    <form @submit.prevent="validateForm()">
        <slot></slot>
    </form>
</template>

<script>
    import { computed } from 'vue';
    import { serialize } from 'object-to-formdata';

    export default {
        name: 'base-form',
        provide() {
            return {
                registerField: this.registerField,
                deregisterField: this.deregisterField,
                hasSubmitted: computed(() => {
                    return this.hasSubmitted;
                }),
            };
        },
        props: {
            form: {
                type: Object,
                default() {
                    return {};
                },
            },
            useFormData: {
                type: Boolean,
                default: false,
            },
        },
        emits: [
            'submit',
        ],
        data() {
            return {
                fields: [],
                hasSubmitted: false,
            };
        },
        computed: {
            formPayload() {
                if (this.useFormData) {
                    return serialize(this.form, {
                        booleansAsIntegers: true,
                    });
                }

                return this.form;
            },
        },
        methods: {
            registerField(field) {
                this.fields.push(field);
            },
            deregisterField(field) {
                const index = this.fields.indexOf(field);

                if (index === -1) {
                    return;
                }

                this.fields.splice(index, 1);
            },
            async validateForm() {
                const errors = this.fields.map(async (field) => {
                    field.setDirtyState();
                    await field.validateField();

                    return field;
                });

                const validatedFields = await Promise.all(errors);
                const isInvalid = validatedFields.some((field) => {
                    return field.hasValidationError;
                });

                this.hasSubmitted = true;

                if (isInvalid) {
                    this.scrollFirstErrorIntoView();

                    return;
                }

                this.$emit('submit', this.formPayload);
            },
            resetFields() {
                this.fields.forEach((field) => {
                    field.resetField();
                });

                this.hasSubmitted = false;
            },
            scrollFirstErrorIntoView() {
                const firstErrorEl = this.$el.querySelector('.field-invalid');

                if (!firstErrorEl) {
                    return;
                }

                this.$scrollTo(firstErrorEl, 250, {
                    offset: -150,
                });

                firstErrorEl.focus();
            },
        },
    };
</script>
